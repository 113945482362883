<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div class="headline">{{ 'edit-csv-labels-dialog.headline.edit' | translate}} '{{device?.name}}'</div>
</div>

<ng-container *ngIf="step == 1">
  <mat-dialog-content fxFlex class="dialog-content">
    <div class="table-container">
      <table mat-table [dataSource]="dataPointsDataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="toggleAllRows()"
                          [checked]="isAllSelected()"
                          [disabled]="device?.isDeleted"
                          [indeterminate]="deviceAddedDataPoints.length > 0 && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="toggle(row)"
                          [checked]="isSelected(row)"
                          [disabled]="device?.isDeleted">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="key">{{'mbus-datapoint-configurator.header.key' | translate}}</th>
          <td mat-cell *matCellDef="let dataPoint">{{dataPoint.key}}</td>
        </ng-container>

        <ng-container matColumnDef="deviceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="deviceName">{{'device-table.header.device-name' | translate}}</th>
          <td mat-cell *matCellDef="let datapoint">{{datapoint.deviceName}}</td>
        </ng-container>

        <ng-container matColumnDef="parentName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="parentName">{{'opcua-datapoint-table.header.parentname' | translate }}</th>
          <td mat-cell *matCellDef="let datapoint">{{datapoint.parentName}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="name">{{'opcua-datapoint-table.header.name' | translate}}</th>
          <td mat-cell *matCellDef="let datapoint">{{datapoint.name}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="description">{{'opcua-datapoint-table.header.description' | translate}}</th>
          <td mat-cell *matCellDef="let datapoint">{{datapoint.description}}</td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="value">{{'mbus-datapoint-configurator.header.value' | translate}}</th>
          <td mat-cell *matCellDef="let datapoint">{{datapoint.lastValue !== null ? formatValue(datapoint) : 'n/a'}}</td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="unit">{{'mbus-datapoint-configurator.header.unit' | translate}}</th>
          <td mat-cell *matCellDef="let datapoint">{{datapoint.unit}}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button [disabled]="deviceAddedDataPoints.length == 0"
                   (click)="exportSelectedDataPoints()"
                   icon="download"
                   text="{{'mbus-datapoint-configurator.button.export' | translate}}">
    </eon-ui-button>
    <eon-ui-link icon="chevron_small_right"
                 icon-position="right"
                 text="{{'mbus-datapoint-configurator.to-upload-label' | translate}}"
                 (click)="gotoStep(2)"></eon-ui-link>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="step == 2">
  <mat-dialog-content fxFlex class="dialog-content">
    <div>
      <div fxLayout="column" fxLayoutGap="25px">
        <p class="stepheadline">{{'edit-csv-labels-dialog.stepper.csv.headline' | translate}}</p>
        <span>{{'mbus-edit-csv-labels-dialog.stepper.csv.text' | translate}}</span>
        <div fxFlex="48">
          <eis-gateway-upload fileType=".csv" (change)="fileChange($event)" [buttonText]="'mbus-datapoint-configurator.button.import' | translate"></eis-gateway-upload>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-link icon="chevron_small_left" text="{{'general.back' | translate}}" (click)="gotoStep(1)"></eon-ui-link>
    <eon-ui-button [disabled]="!file" (click)="checkCSV()" [disabled]="!checkEnabled"
                   text="{{'general.button.check-label-process' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>


<ng-container *ngIf="step == 3">
  <mat-dialog-content fxFlex>
    <div *ngIf="csvDeviceErrors.length > 0">
      <p class="stepheadline">{{'add-device-dialog.datapoints.error' | translate}}</p>
      <p>{{csvDeviceErrors.length}} Errors</p>
      <div fxLayout="column">
        <p class="stepsubheadline" *ngIf="csvDeviceErrors.length > 0">{{'add-device-dialog.csv.error' | translate}}</p>
        <ul>
          <li *ngFor="let error of csvDeviceErrors">
            <div><span>Line: </span>{{error.position}}</div>
            <div><span>Datapoint Key: </span>{{error.elementHint}}</div>
            <div><span>Message: </span><span class="error-message" [innerHTML]="error.message"></span></div>
          </li>
        </ul>
      </div>
    </div>


    <div *ngIf="csvDeviceErrors.length === 0">
      <p class="stepheadline">{{'edit-csv-labels-dialog.datapoints.success' | translate}}</p>

      <div fxLayout="column" fxLayoutGap="35px">

      </div>
      <div fxLayout="column" fxLayoutGap="35px">
        <eis-gateway-checkmark></eis-gateway-checkmark>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <div fxLayout="row" fxLayoutGap="35px" style="align-items:center">
      <eon-ui-link icon="chevron_small_left" text="{{'general.back' | translate}}"
                   (click)="gotoStep(2)"
                   *ngIf="checkDeviceErrors.length > 0"></eon-ui-link>

      <eon-ui-button scheme="red" rank="secondary" icon="close" text="{{'general.cancel' | translate}}"
                     [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>
    </div>

    <div fxLayout="row" fxLayoutGap="35px">
      <eon-ui-button scheme="red" rank="secondary" (click)="copyErrorToClipboard()" text="{{'general.copy-to-clipboard' | translate}}"
                     *ngIf="checkDeviceErrors.length > 0"></eon-ui-button>

      <eon-ui-button (click)="confirmDevice()" text="{{'general.button.complete-process' | translate}}"
                     *ngIf="csvDeviceErrors.length == 0"></eon-ui-button>
    </div>
  </mat-dialog-actions>

</ng-container>
