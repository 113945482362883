import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { OPCUADeviceService } from '../../../../services/opcua-device.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OPCUADevice } from '../../../../domain/opcua-device';
import { OPCUADataPoint } from '../../../../domain/opcua-data-point';
import { OPCUAConfig } from '../../../../domain/opcua-config';
import { NotificationService } from '../../../../services/notification.service';
import { DynamicFlatNode } from '../../../../domain/dynamic-flat-node';
import { DynamicDataSource } from '../../../../domain/dynamic-data-source';
import { OpcuaUnitBrowserDialogComponent } from '../opcua-unit-browser-dialog/opcua-unit-browser-dialog.component';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'eis-gateway-opcua-browser-dialog',
  templateUrl: './opcua-browser-dialog.component.html',
  styleUrls: ['./opcua-browser-dialog.component.scss']
})
export class OpcuaBrowserDialogComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  public treeControl: FlatTreeControl<DynamicFlatNode>;
  public treeDataSource: DynamicDataSource;
  public dataPoints: MatTableDataSource<OPCUADataPoint> = new MatTableDataSource<OPCUADataPoint>();
  public deviceAddedDataPoints: OPCUADataPoint[] = [];
  public deviceRemovedDataPoints: OPCUADataPoint[] = [];
  public activeNode: DynamicFlatNode;
  public pollingFrequencies: {[id: string]: string} = {};
  public units: {[id: string]: string} = {};
  public loadingInitialTree: boolean = true;
  private opcuaConfig: OPCUAConfig[] = [];

  //public displayedColumns: string[] = ['browseName', 'dataType', 'name', 'nodeId', 'value'];
  public displayedColumns: string[] = ['select', 'parentName', 'name', 'value', 'unit', 'pollingFrequency', 'actions'];
  private START_NODE_ID = "ns=0;i=85";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {device: OPCUADevice, activeSerial: string},
    public dialogRef: MatDialogRef<OpcuaBrowserDialogComponent>,
    private dialog: MatDialog,
    private deviceService: OPCUADeviceService,
    private notificationService: NotificationService,
  ) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.treeDataSource = new DynamicDataSource(data.device, this.treeControl, deviceService);
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  async ngOnInit(): Promise<void> {
    this.data.device.gatewaySerial = this.data.activeSerial;
    this.deviceService.getDeviceConfig(this.data.activeSerial, this.data.device.id).subscribe(configs => {
      this.opcuaConfig = configs;

      this.deviceAddedDataPoints = this.createDataPointsFromConfigs();
      this.deviceAddedDataPoints = this.sortDataPointsBySelection(this.deviceAddedDataPoints);

      this.dataPoints.data = this.createDataPointsFromConfigs();
      this.dataPoints.data = this.sortDataPointsBySelection(this.dataPoints.data);
      this.dataPoints.sort = this.sort;
      this.dataPoints.sortingDataAccessor = (item, property) => {
        if (property === 'parentName') {
          return item.parent_name;
        } else if (property === 'name') {
          return item.name;
        } else if (property === 'value') {
          return parseFloat(item.value);
        } else if (property === 'unit') {
          return item.unit;
        }

        return "";
      };
      this.fillPollingFrequencies();
      this.fillUnits();
      this.unselectDeletedDataPoints();
    });

    this.loadingInitialTree = true;
    this.deviceService.browseChildren(this.data.device, this.START_NODE_ID).subscribe(parents=> {
      this.loadingInitialTree = false;
      if(parents == null) {
        parents = [];
      }
      this.treeDataSource.data = parents.map(
        parent => new DynamicFlatNode(parent.node_id, parent.node_name, parent.description, 1, parent.has_children, false, parent.opcua_node_class),
      );
    });
  }

  async loadDataPoints($event: any, node: DynamicFlatNode) {
    $event.stopPropagation()
    const dataPoints = await firstValueFrom(this.deviceService.getDataPoints(this.data.device, node.id));
    if(dataPoints == null || dataPoints.length == 0) {
      this.notificationService.failure('add-opcua-browser-dialog.datapoint-load-error')
      return;
    }

    try {
      const parsedValue = JSON.parse(dataPoints[0].value);
      if(typeof(parsedValue) == "object") {
        this.notificationService.failure('add-opcua-browser-dialog.datapoint-not-allowed')
        return;
      }
    } catch(e) {}

    this.dataPoints.data = dataPoints;
    for (let dp of this.dataPoints.data) {
      dp.name = node.name;
      dp.parent_name = node.parent?.name ?? "";
      dp.description = node.description;
    }

    for(let dp of this.deviceAddedDataPoints) {
      if(this.findDataPointIndex(this.dataPoints.data, dp) == -1) {
        this.dataPoints.data.push(dp);
      }
    }

    for (let dp of this.dataPoints.data) {
      this.selectDataPoint(dp);
    }

    //this.selectAddedDataPoints();
    this.fillPollingFrequencies();
    this.fillUnits();
    this.dataPoints.data = this.sortDataPointsBySelection(this.dataPoints.data);
  }

  async applyChanges() {
    const updatedConfigs: OPCUAConfig[] = [];
    for (let dp of this.deviceAddedDataPoints) {
      const config = this.getConfig(dp);
      if(config == undefined) {
        updatedConfigs.push({
          nodeId: dp.node_id,
          browseName: dp.browse_name,
          parentName: dp.parent_name,
          name: dp.name,
          description: dp.description,
          unit: dp.unit ? dp.unit : 'none',
          pollingFrequency: parseInt(this.pollingFrequencies[dp.node_id]),
          scaleFactor: 1,
          dataType: dp.data_type,
          value: dp.value,
          actionType: "add",
          isDeployed: false,
          deviceId: this.data.device.id,
        } as OPCUAConfig);
      }
    }

    //deleted deployed data points
    for (let dp of this.deviceRemovedDataPoints) {
      const config = this.getConfig(dp);
      if(!config?.actionType) {
        updatedConfigs.push({
          id: config?.id,
          nodeId: dp.node_id,
          browseName: dp.browse_name,
          parentName: dp.parent_name,
          name: dp.name,
          description: dp.description,
          pollingFrequency: parseInt(this.pollingFrequencies[dp.node_id]),
          unit: dp.unit ? dp.unit : 'none',
          scaleFactor: 1,
          dataType: dp.data_type,
          value: dp.value,
          actionType: "delete",
          isDeployed: false,
          deviceId: this.data.device.id,
        } as OPCUAConfig);
      }
    }

    const configsWithDifferentPollingFrequency = this.opcuaConfig.filter(config => config.pollingFrequency.toString() != this.pollingFrequencies[config.nodeId].toString());
    for (const config of configsWithDifferentPollingFrequency) {
      config.pollingFrequency = parseInt(this.pollingFrequencies[config.nodeId]);
      config.actionType = "update";
      config.isDeployed = false;
      updatedConfigs.push(config);
    }

    const configsWithDifferentUnit = this.opcuaConfig.filter(config => config.unit != this.units[config.nodeId]);
    for (const config of configsWithDifferentUnit) {
      config.actionType = "update";
      config.isDeployed = false;
      updatedConfigs.push(config);
    }

    const savedConfigs = await firstValueFrom(this.deviceService.updateDeviceConfig(this.data.activeSerial, updatedConfigs));
    for(let config of savedConfigs) {
      this.opcuaConfig = this.opcuaConfig.filter(c => c.id != config.id);
      this.opcuaConfig.push(config);
    }

    //removed undeployed data points
    const removedConfigs: OPCUAConfig[] = [];
    for (let dp of this.deviceRemovedDataPoints) {
      const config = this.getConfig(dp);
      if(config?.actionType == "add") {
        removedConfigs.push({
          id: config?.id,
          nodeId: dp.node_id,
          browseName: dp.browse_name,
          parentName: dp.parent_name,
          name: dp.name,
          description: dp.description,
          pollingFrequency: parseInt(this.pollingFrequencies[dp.node_id]),
          unit: dp.unit ? dp.unit : 'none',
          scaleFactor: 1,
          dataType: dp.data_type,
          value: dp.value,
          actionType: 'remove',
          isDeployed: false,
          deviceId: this.data.device.id,
        } as OPCUAConfig);
      }
    }

    await firstValueFrom(this.deviceService.removeDeviceConfig(this.data.activeSerial, removedConfigs));
    for(let config of removedConfigs) {
      this.opcuaConfig = this.opcuaConfig.filter(c => c.id != config.id);
    }
    this.deviceRemovedDataPoints = [];

    this.notificationService.success('Data points have been saved successfully');

    this.dialog.closeAll();
  }

  toggleAllRows() {
    if (this.isAllSelected() || this.isNoneSelected()) {
      for(let i = 0; i < this.dataPoints.data.length; i++) {
        this.toggle(this.dataPoints.data[i]);
      }
    }
    else {
      for(let i = 0; i < this.dataPoints.data.length; i++) {
        if(this.findDataPointIndex(this.deviceAddedDataPoints, this.dataPoints.data[i]) == -1) {
          this.toggle(this.dataPoints.data[i]);
        }
      }
    }
  }

  isAllSelected() {
    const numSelected = this.deviceAddedDataPoints.length;
    const numRows = this.dataPoints.data.length;
    return numSelected === numRows;
  }

  isNoneSelected() {
    return this.deviceAddedDataPoints.length == 0;
  }

  findDataPointIndex(dataPoints: OPCUADataPoint[], point: OPCUADataPoint): number {
    return dataPoints.findIndex(dt => dt.node_id == point.node_id);
  }

  isSelected(dataPoint: OPCUADataPoint): boolean {
    return this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint) != -1;
  }

  toggle(dataPoint: OPCUADataPoint) {
    let index = this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint);
    if(index > -1) {
      this.deviceAddedDataPoints.splice(index, 1);

      if(this.getConfig(dataPoint)) {
        this.deviceRemovedDataPoints.push(dataPoint);
      }
    } else {
      this.selectDataPoint(dataPoint);
    }
  }

  selectDataPoint(dataPoint: OPCUADataPoint) {
    let index = this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint);
    if(index == -1) {
      this.deviceAddedDataPoints.push(dataPoint);
      const config = this.getConfig(dataPoint);
      this.pollingFrequencies[dataPoint.node_id] = config?.pollingFrequency ? config?.pollingFrequency + "" : "60";

      index = this.findDataPointIndex(this.deviceRemovedDataPoints, dataPoint);
      if(index > -1) {
        this.deviceRemovedDataPoints.splice(index, 1);
      }
    }
  }

  private getConfig(dataPoint: OPCUADataPoint) : OPCUAConfig | undefined {
    return this.opcuaConfig.find(conf => conf.nodeId == dataPoint.node_id);
  }

  private fillPollingFrequencies() {
    for(let dataPoint of this.deviceAddedDataPoints) {
      if(!this.pollingFrequencies[dataPoint.node_id]) {
        const config = this.getConfig(dataPoint);
        this.pollingFrequencies[dataPoint.node_id] = config?.pollingFrequency ? config?.pollingFrequency + "" : "60";
      }
    }
  }

  private fillUnits() {
    for(let dataPoint of this.deviceAddedDataPoints) {
      if(!this.units[dataPoint.node_id]) {
        const config = this.getConfig(dataPoint);
        this.units[dataPoint.node_id] = config?.unit ? config?.unit + "" : "none";
      }
    }
  }

  private selectAddedDataPoints() {
    this.deviceAddedDataPoints = this.dataPoints.data.filter(d => this.opcuaConfig.find(c => c.nodeId == d.node_id));
  }

  private sortDataPointsBySelection(dataPoints: OPCUADataPoint[]): OPCUADataPoint[] {
    const selectedDataPoints = this.deviceAddedDataPoints;
    return dataPoints.sort((a, b) =>
      this.isTemporary(a) && !this.isTemporary(b)
        ? -1
        : this.isTemporary(b) && !this.isTemporary(a)
          ? 1
          : (a.parent_name + a.name).localeCompare(b.parent_name + b.name)
    );
  }

  isNew(dataPoint: OPCUADataPoint): boolean {
    const config = this.getConfig(dataPoint);
    return this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint) != -1 && (!config || config.actionType == "add");
  }

  isTemporary(dataPoint: OPCUADataPoint): boolean {
    return this.getConfig(dataPoint) == undefined;
  }

  isAdded(dataPoint: OPCUADataPoint): boolean {
    const config = this.getConfig(dataPoint);
    return !!config && config.actionType != "delete";
  }

  isDeleted(dataPoint: OPCUADataPoint): boolean {
    return this.findDataPointIndex(this.deviceRemovedDataPoints, dataPoint) != -1;
  }

  private createDataPointsFromConfigs(): OPCUADataPoint[] {
    const result: OPCUADataPoint[] = [];
    for(let config of this.opcuaConfig) {
      result.push({
        node_id: config.nodeId,
        browse_name: config.browseName,
        parent_name: config.parentName,
        name: config.name,
        data_type: config.dataType,
        value: (parseFloat(config.value) / config.scaleFactor).toString(),
        unit: config.unit,
      } as OPCUADataPoint);
    }

    return result;
  }

  showUnitDialog(dataPoint: OPCUADataPoint) {
    const dialogRef = this.dialog.open(OpcuaUnitBrowserDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        device: this.data.device,
        parentNode: dataPoint,
      },
      width: '650px',
      height: '610px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.unit) {
        dataPoint.unit = result.unit;
        const config = this.getConfig(dataPoint);
        if(config) {
          config.originalUnit = result.unit;
          config.unit = result.unit;
        }
      }
    });
  }

  formatValue(dataPoint: OPCUADataPoint) {
    if(dataPoint.value.includes(".")) {
      const floatValue = parseFloat(dataPoint.value);
      if(floatValue.toString() == dataPoint.value) {
        const parts = floatValue.toString().split('.');
        if (parts[1].length > 3) {
         return floatValue.toFixed(3);
        }
      }
    }

    return dataPoint.value;
  }

  deleteDataPoint(dataPoint: OPCUADataPoint) {
    const config = this.getConfig(dataPoint);
    if(config) {
      return;
    }

    this.dataPoints.data = this.dataPoints.data.filter(dp => dp.node_id != dataPoint.node_id);
    this.deviceAddedDataPoints = this.deviceAddedDataPoints.filter(dp => dp.node_id != dataPoint.node_id);
  }

  private unselectDeletedDataPoints() {
    for(let dp of this.deviceAddedDataPoints) {
      const config = this.getConfig(dp);
      if(config?.actionType == "delete") {
        this.toggle(dp);
      }
    }
  }
}
