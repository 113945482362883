import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DeviceService} from "../../../services/device.service";
import {ComportService} from "../../../services/comport.service";
import { Comport, ComportProtocols } from "../../../domain/comport";
import { map, Observable } from "rxjs";
import { Device, DeviceLabel } from '../../../domain/device.interface';
import { NotificationService } from '../../../services/notification.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material/sidenav';

interface ErrorDetail {
  category: String,
  elementHint: String,
  message: String,
  position: String
}

@Component({
  selector: 'eis-gateway-add-device-dialog',
  templateUrl: './add-device-dialog.component.html',
  styleUrls: ['./add-device-dialog.component.scss']
})
export class AddDeviceDialogComponent implements OnInit {
  public nameInput: FormControl<string | null>;
  public ipAddressInput: FormControl<string | null>;
  public unitIdInput: FormControl<string | null>;
  public portNumberInput: FormControl<string | null>;
  public protocolAdapterSelection: FormControl;
  public comportSelection: FormControl<string | null>;
  public aggregateInput: FormControl<number | null>;
  public labels: FormControl<string | null>;
  public dataPointLabels: FormControl<string | null>;

  file: File | null;
  step: number = 1;
  checkDeviceErrors: ErrorDetail[] = [];
  validationDeviceErrors: ErrorDetail[] = [];
  csvDeviceErrors: ErrorDetail[] = [];
  availablePorts$: Observable<Comport[]>;
  aggregateDisabled: boolean = false;
  device: Device;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {device: Device, activeSerial: string},
              public dialogRef: MatDialogRef<AddDeviceDialogComponent>,
              public comportService: ComportService,
              public deviceService: DeviceService,
              public notificationService: NotificationService,
              private clipboard: Clipboard,
              private translateService: TranslateService) {

    this.nameInput = new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9!@#$%^&*()_+,\\-.?;:\\[\\]{}|]+$")]);
    this.ipAddressInput = new FormControl('', [Validators.required, Validators.pattern("(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)")]);
    this.unitIdInput = new FormControl('', [Validators.required, Validators.min(1), Validators.max(254)]);
    this.portNumberInput = new FormControl('', [Validators.required, Validators.min(502), Validators.max(65535)]);
    this.protocolAdapterSelection = new FormControl({disabled: this.data.device != null});
    this.comportSelection = new FormControl('', [Validators.required]);
    this.aggregateInput = new FormControl(50, [Validators.required]);
    this.labels = new FormControl('');
    this.dataPointLabels = new FormControl('');
  }

  get checkEnabled(): boolean {
    if (this.protocolAdapterSelection.value) {
      return (this.device != null ||  this.nameInput.valid) &&
        this.ipAddressInput.valid &&
        this.unitIdInput.valid &&
        this.portNumberInput.valid &&
        this.aggregateInput.valid &&
        (this.device != null || this.file != null);
    } else {
      return (this.device != null ||  this.nameInput.valid) &&
        this.aggregateInput.valid &&
        this.unitIdInput.valid &&
        this.comportSelection.valid &&
        (this.device != null || this.file != null);
    }
  }

  ngOnInit(): void {
    this.protocolAdapterSelection.patchValue(true);

    this.availablePorts$ = this.comportService.getComportConfigs(this.data.activeSerial)
      .pipe(
        map(ports => this.filterRTUPorts(ports))
      );

    if(this.data.device.deviceName) {
      this.deviceService.getDeviceByName(this.data.activeSerial, this.data.device.deviceName)
        .subscribe(device => this.labels.setValue(this.deviceLabelsToString(device))
        );
    }

    this.device = this.data.device;
    if(this.device != null) {
      this.fillFormControls();
    }
  }

  backToForm() {
    this.step = 1;
  }

  confirmDevice() {
    if (this.protocolAdapterSelection.value) {
      this.deviceService.createDevice(
        this.nameInput.value!,
        this.ipAddressInput.value!,
        this.portNumberInput.value!,
        this.unitIdInput.value!,
        this.data.activeSerial,
        this.aggregateDisabled ? this.aggregateInput.value! : 50,
        this.file!,
        this.getLabels(this.labels),
        this.getLabels(this.dataPointLabels)
      ).subscribe({
        next: (response) => this.dialogRef.close({status: "200", device: response}),
        error: (err) => {
          this.dialogRef.close({status: err.status.toString()})
        }
      });
    } else {
      this.deviceService.createRtuDevice(
        this.nameInput.value!,
        this.unitIdInput.value!,
        this.comportSelection.value!,
        this.data.activeSerial,
        this.aggregateDisabled ? this.aggregateInput.value! : 10,
        this.file!,
        this.getLabels(this.labels),
        this.getLabels(this.dataPointLabels)
      ).subscribe({
        next: (response) => this.dialogRef.close({status: "200", device: response}),
        error: (err) => {
          this.dialogRef.close({status: err.status.toString()})
        }
      });
    }

  }

  validateAndUpdate() {
    this.deviceService.validateDevice(
      this.device.id!,
      this.nameInput.value!,
      this.ipAddressInput.value!,
      this.portNumberInput.value!,
      this.comportSelection.value!,
      this.unitIdInput.value!,
      this.data.activeSerial,
      this.aggregateDisabled ? this.aggregateInput.value! : 50,
      this.file!,
      this.getLabels(this.labels),
      this.getLabels(this.dataPointLabels)
    ).subscribe({
      next: (errorResults) => {
        if(errorResults.length == 0) {
          this.updateDevice();
        } else {
          this.notificationService.failure(errorResults.map((e: any) => e.message).join("<br />"));
        }
      }
    });
  }

  updateDevice() {
    if (this.protocolAdapterSelection.value) {
      this.deviceService.updateDevice(
        this.device.id!,
        this.nameInput.value!,
        this.ipAddressInput.value!,
        this.portNumberInput.value!,
        this.unitIdInput.value!,
        this.data.activeSerial,
        this.aggregateDisabled ? this.aggregateInput.value! : 50,
        this.getLabels(this.labels),
        this.getLabels(this.dataPointLabels)
      ).subscribe({
        next: (response) => this.dialogRef.close({status: "200", device: response}),
        error: (err) => {
          this.dialogRef.close({status: err.status.toString()})
        }
      });
    } else {
      this.deviceService.updateRtuDevice(
        this.device.id!,
        this.nameInput.value!,
        this.unitIdInput.value!,
        this.comportSelection.value!,
        this.data.activeSerial,
        this.aggregateDisabled ? this.aggregateInput.value! : 10,
        this.getLabels(this.labels),
        this.getLabels(this.dataPointLabels)
      ).subscribe({
        next: (response) => this.dialogRef.close({status: "200", device: response}),
        error: (err) => {
          this.dialogRef.close({status: err.status.toString()})
        }
      });
    }
  }

  checkDevice() {
    if (this.protocolAdapterSelection.value) {
      this.deviceService.checkCreateDevice(
        this.nameInput.value!,
        this.ipAddressInput.value!,
        this.portNumberInput.value!,
        this.unitIdInput.value!,
        this.data.activeSerial,
        this.aggregateDisabled ? this.aggregateInput.value! : 50,
        this.file!,
        this.getLabels(this.labels),
        this.getLabels(this.dataPointLabels)
      ).subscribe({
        next: (errorResults) => {
          this.checkDeviceErrors = errorResults;
          this.validationDeviceErrors = this.checkDeviceErrors.filter((e) => e.category == "Conflict");
          this.csvDeviceErrors = this.checkDeviceErrors.filter((e) => e.category != "Conflict");
          this.step = 2;
        }
      });
    } else {
      this.deviceService.checkCreateRtuDevice(
        this.nameInput.value!,
        this.comportSelection.value!,
        this.unitIdInput.value!,
        this.data.activeSerial,
        this.aggregateDisabled ? this.aggregateInput.value! : 10,
        this.file!,
        this.getLabels(this.labels),
        this.getLabels(this.dataPointLabels)
      ).subscribe({
        next: (errorResults) => {
          this.checkDeviceErrors = errorResults;
          this.validationDeviceErrors = this.checkDeviceErrors.filter((e) => e.category == "Conflict");
          this.csvDeviceErrors = this.checkDeviceErrors.filter((e) => e.category != "Conflict");
          this.step = 2;
        }
      });
    }
  }

  fileChange($event: Event) {
    this.file = ($event.target as HTMLInputElement).files![0];
  }

  public compareFn(object1: any, object2: any) {
    return object1 && object2 && object1 == object2;
  }

  getLabels(labelControl: FormControl) : DeviceLabel[] {
    const stringValues = labelControl.value?.split(',');
    const result: DeviceLabel[] = [];
    if(stringValues == null ||stringValues.length == 0) {
      return result;
    }

    for(let keyValue of stringValues) {
      if(!keyValue) {
        continue;
      }

      const pairs = keyValue.split(":");
      if(pairs.length == 1) {
        pairs.push("");
      }

      result.push({
        key: pairs[0]?.trim(),
        value: pairs[1]?.trim(),
        deleted: !pairs[1],
      });
    }

    return result;
  }

  private fillFormControls() {
    this.nameInput.setValue(this.device.deviceName);
    this.nameInput.disable();

    this.aggregateInput.setValue(this.device.aggregationBatchSize);
    this.aggregateDisabled = this.device.aggregationBatchSize != 50;
    this.ipAddressInput.setValue(this.device.ipAddress);
    this.portNumberInput.setValue(this.device.portNumber);
    this.unitIdInput.setValue(this.device.unitId);

    if(this.device.portName != null) {
      this.protocolAdapterSelection.setValue(false);
      this.comportSelection.setValue(this.device.portName);
    }

    const labels = this.device.labels?.map(l => l.key + ":" + l.value).join(",")
    if(labels) {
      this.labels.setValue(labels);
    }
  }

  async copyErrorToClipboard() {
    if(this.csvDeviceErrors.length == 0) {
      return;
    }

    const errors = [];
    errors.push(this.translateService.instant("add-device-dialog.csv.error"));
    for(let csvError of this.csvDeviceErrors) {
      let errorAsString = "Line: " + csvError.position + "\n";
      errorAsString += "Element hint: " + csvError.elementHint + "\n";
      errorAsString += "Message: " + csvError.message + "\n";
      errors.push(errorAsString);
    }
    this.clipboard.copy(errors.join("\n"));
    await this.notificationService.success("Copied to clipboard");
  }

  private filterRTUPorts(ports: Comport[]) {
    return ports.filter(p => p.protocol == ComportProtocols.ModbusRtu && this.portNotInUseByMbus(p, ports));
  }

  private portNotInUseByMbus(port: Comport, ports: Comport[]) {
    let otherPort = ports.find(p => p.port == port.port && p.portName !== port.portName)
      return (!(otherPort!!.inUse && otherPort!!.protocol == ComportProtocols.MBus));
  }

  transferLabels() {
    this.dataPointLabels.patchValue(this.labels.value);
  }

  private deviceLabelsToString(device: Device) {
    if(!device?.labels) {
      return "";
    }

    return JSON.stringify(device.labels)
      .replace(/"/g, "")
      .replace(/",/g, ", ")
      .replace("{", "")
      .replace("}", "");
  }
}

