<div mat-dialog-title class="dialog-title">
  <div class="headline">{{ 'gateway-table.popupmenu.diagnostics' | translate}}</div>
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX name="close"></eon-ui-icon>
</div>

<mat-dialog-content fxFlex class="dialog-content">
  <div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
      <label class="action-label">Ping device: </label>

      <mat-form-field fxFlex appearance="outline" class="eon-style-form-field no-padding" color="accent">
        <input [formControl]="pingInputControl"
               matInput
               class="eon-style-input"
               type="text"
               placeholder="IP-address or name"
        >
      </mat-form-field>

      <div class="action-button-container">
        <eon-ui-button [text]="'Test'" class="action-button" (click)="ping()"></eon-ui-button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
      <label class="action-label">Connect to service: </label>

      <mat-form-field fxFlex appearance="outline" class="eon-style-form-field no-padding" color="accent">
        <input [formControl]="telnetIpControl"
               matInput
               class="eon-style-input"
               type="text"
               placeholder="IP-address or name"
        >
      </mat-form-field>

      <label>Port: </label>

      <mat-form-field fxFlex appearance="outline" class="eon-style-form-field no-padding port-number" color="accent" >
        <input [formControl]="telnetPortControl"
               matInput
               class="eon-style-input"
               type="text"
               placeholder="Port"
        >
      </mat-form-field>
      <div class="action-button-container">
        <eon-ui-button [text]="'Test'" class="action-button hydrated eonui-full-width" (click)="telnet()" full-width="true"></eon-ui-button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="load-log-container">
      <label class="action-label">Load log info from Gateway </label>

      <div class="action-button-container">
        <eon-ui-button [text]="'Update'" class="action-button" (click)="getGatewayLogs()"></eon-ui-button>
      </div>
    </div>

    <div fxLayout="column" class="log-filter-type-container">
      <label class="filter-action-label">Log filter</label>
      <mat-radio-group aria-label="Select filter type" [(ngModel)]="logFilterType">
        <mat-radio-button value="modbus,mbus,opcua">All</mat-radio-button>
        <mat-radio-button value="modbus">Modbus</mat-radio-button>
        <mat-radio-button value="mbus">M-Bus</mat-radio-button>
        <mat-radio-button value="opcua">OPC UA</mat-radio-button>
      </mat-radio-group>
    </div>

    <div fxLayout="column" fxFlexFill>
      <label class="response-label">Response from Gateway</label>
      <div class="logs-container">
        <ng-container *ngFor="let line of logLines">
          <div class="log-line" [innerHTML]="line"></div>
        </ng-container>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: space-between;">
    <label class="action-label">Browse Log Data in Splunk</label>

    <eon-ui-button [text]="'Go to Splunk'" class="action-button" (click)="openGatewayLog()"></eon-ui-button>
</mat-dialog-actions>
