import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import { Observable, of } from "rxjs";
import {HttpClient} from "@angular/common/http";
import {EiotGateway} from "../domain/eiot-gateway.interface";
import {EiotTenant} from "../domain/eiot-tenant.interface";
import {checkFullyConnected} from "../components/decorators/checkConnectedDecorator";
import {triggerLoader} from "../components/decorators/loaderDecorator";
import {ModbusWritePayload} from "../domain/modbus-write-payload";
import { Announcement } from '../domain/announcement';

@Injectable({
  providedIn: 'root'
})
export class EonIotService {

  private readonly SERVICE_URL = `${environment.backendUrl}/eon-iot`;

  constructor(private http: HttpClient) {
  }

  public getGateways(tenant: string): Observable<EiotGateway[]> {
    return this.http.get<EiotGateway[]>(this.SERVICE_URL + '/gateways',
      {
        params:
          {tenant: tenant}
      });
  }

  @triggerLoader
  public getTenants(): Observable<EiotTenant[]> {
    return this.http.get<EiotTenant[]>(this.SERVICE_URL + '/tenants')
  }

  @checkFullyConnected
  addGateway(tenant: string, serial: string, startcode: string, location: string, developmentImageEnabled: boolean): Observable<EiotGateway> {
    return this.http.post<EiotGateway>(this.SERVICE_URL + '/gateways', {tenant, serial, startcode, location, developmentImageEnabled});
  }

  changeGateway(serial: string, tenant: string, location: string): Observable<EiotGateway> {
    return this.http.patch<EiotGateway>(this.SERVICE_URL + '/gateways', {tenant, serial, location});
  }

  checkGateway(tenant: string, serial: string): Observable<boolean> {
    return this.http.post<boolean>(this.SERVICE_URL + '/gateways/check', {tenant, serial, location: ''});
  }

  writeModbus(payload: ModbusWritePayload, serial: string): Observable<void> {
    return this.http.post<void>(this.SERVICE_URL + "/" + serial + "/modbus-write", payload);
  }

  @checkFullyConnected
  getAnnouncements(): Observable<Array<Announcement>> {
    return this.http.get<Array<Announcement>>(this.SERVICE_URL + '/announcement');
  }
}
