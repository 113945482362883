<div fxLayout="column" class="main-container">
  <div class="device-header-container">
    <div class="action-container">
      <eon-ui-button [text]="'add-opcua-server-dialog.button.add' | translate"
                     icon="add" scheme="red"
                     (click)="addOPCUADevice()">
      </eon-ui-button>

      <eon-ui-button [text]="'mbus-device-table.buttons.preview-changes' | translate" *ngIf="hasChanges()"
                     icon="meter_read_no_read"
                     scheme="red"
                     rank="secondary"
                     (click)="previewUndeployedChanges()"></eon-ui-button>
    </div>
  </div>

  <div class="table-container">
    <table mat-table [dataSource]="devices" matSort>
      <ng-container matColumnDef="hasUndeployedDataPoints">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="hasUndeployedDataPoints">{{'mbus-device-table.header.has-undeployed-data-points' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">
          <div *ngIf="device.hasUndeployedDataPoints || device.isDeleted">
            <mat-icon class="red-point">circle</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="online">
        <th mat-header-cell *matHeaderCellDef>{{'gateway-table.header.online-status' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">
          <mat-icon *ngIf="device.online != false" [class.wifi-on]="device.online == true">wifi</mat-icon>
          <mat-icon *ngIf="device.online == false" class="wifi-off">wifi_off</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="deviceName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header="deviceName">{{'device-table.header.device-name' | translate}}</th>

        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">
          {{device.name}}
        </td>

      </ng-container>


      <ng-container matColumnDef="serverAddress">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header="ipAddress">{{'device-table.header.ip-address' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500"> {{device.serverAddress}} </td>
      </ng-container>

      <ng-container matColumnDef="portNumber">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header="portNumber">{{'device-table.header.port-number' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500"> {{device.portNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="menuAction">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
        <td mat-cell *matCellDef="let device" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{device: device}">
          <mat-icon class="clickable">more_vert</mat-icon>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        (click)="deviceClicked(row)"
        mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-device="device">
    <button mat-menu-item (click)="deviceClicked(device)">
      <mat-icon>edit</mat-icon>
      {{'device-table.popupmenu.csv-edit' | translate}}
    </button>

    <button mat-menu-item (click)="openDeletionDialog(device)" [disabled]="device.isDeleted">
      <mat-icon svgIcon="eon-trash-bin"></mat-icon>
      {{'device-table.popupmenu.delete' | translate}}
    </button>

    <button mat-menu-item (click)="openCSVLabelsDialog(device)" [disabled]="deviceDataPoints[device.id] == 0">
      <mat-icon>edit</mat-icon>
      {{'device-table.popupmenu.csv-label-edit' | translate}}
    </button>
  </ng-template>
</mat-menu>
