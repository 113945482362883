import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OPCUADeviceService } from '../../../../services/opcua-device.service';
import { OPCUADevice } from '../../../../domain/opcua-device';

@Component({
  selector: 'eis-gateway-delete-opcua-device-dialog',
  templateUrl: './delete-opcua-device-dialog.component.html',
  styleUrls: ['./delete-opcua-device-dialog.component.scss']
})
export class DeleteOpcuaDeviceDialogComponent implements OnInit {

  device: OPCUADevice;

  deleteDatapoints: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { serial: string, serialPort: string, device: OPCUADevice },
              public dialogRef: MatDialogRef<DeleteOpcuaDeviceDialogComponent>,
              private opcuaDeviceService: OPCUADeviceService) {

  }

  ngOnInit(): void {
    this.device = this.data.device;
  }

  deleteDevice() {
    this.opcuaDeviceService.deleteDevice(this.device, this.deleteDatapoints)
      .subscribe(value => this.dialogRef.close({
        success: true,
      }), error => this.dialogRef.close({success: false, error: error}))
  }
}
