import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OPCUADeviceService } from '../../../../services/opcua-device.service';
import { OPCUADevice } from '../../../../domain/opcua-device';
import { MatDialog } from '@angular/material/dialog';
import { OpcuaBrowserDialogComponent } from '../opcua-browser-dialog/opcua-browser-dialog.component';
import { AddOpcuaDeviceDialogComponent } from '../add-opcua-device-dialog/add-opcua-device-dialog.component';
import { NotificationService } from '../../../../services/notification.service';
import { OpcuaPreviewChangesComponent } from '../opcua-preview-changes/opcua-preview-changes.component';
import { OPCUAConfig } from '../../../../domain/opcua-config';
import { DeleteOpcuaDeviceDialogComponent } from '../delete-opcua-device-dialog/delete-opcua-device-dialog.component';
import {
  OpcuaEditCsvLabelsDialogComponent
} from '../opcua-edit-csv-labels-dialog/opcua-edit-csv-labels-dialog.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'eis-gateway-opcua-device-table',
  templateUrl: './opcua-device-table.component.html',
  styleUrls: ['./opcua-device-table.component.scss']
})
export class OpcuaDeviceTableComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  public gatewaySerial: string | undefined;

  public devices: MatTableDataSource<OPCUADevice> = new MatTableDataSource();

  public displayedColumns: string[] = ['hasUndeployedDataPoints', 'online', 'deviceName', 'serverAddress', 'portNumber', 'menuAction'];
  public deviceDataPoints: {[key: string]: number} = {};

  constructor(
    private opcuaDeviceService: OPCUADeviceService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getOPCUADevices();
  }

  private getOPCUADevices() {
    this.opcuaDeviceService.getAllDevices(this.gatewaySerial).subscribe(devices => {
      this.devices.data = devices;
      this.setDeviceDataPoints();
    });
  }

  addOPCUADevice() {
    const dialogRef = this.dialog.open(AddOpcuaDeviceDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.gatewaySerial,
        device: null
      },
      width: '692px',
      height: '736px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if(res.status == "200") {
        this.getOPCUADevices();
        this.notificationService.success('gateway-details.snackbar.device-add.success');
      } else {
        this.notificationService.failure(res.error)
      }
    });
  }

  deviceClicked(device: OPCUADevice) {
    if(device.isDeleted) {
      this.notificationService.failure('opcua-delete-device.snackbar.cannotbrowse');
      return;
    }

    const dialogRef = this.dialog.open(OpcuaBrowserDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        device: device,
        activeSerial: this.gatewaySerial
      },
      width: '90%',
      height: '90%',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((_) => {
      this.getOPCUADevices();
    });
  }

  hasChanges(): boolean {
    return !!this.devices.data.find(d => d.hasUndeployedDataPoints || d.isDeleted);
  }

  previewUndeployedChanges() {
    let deviceIds = this.devices.data.map(m => m.id);
    deviceIds = [...new Set(deviceIds)];
    this.opcuaDeviceService.getGatewayConfig(this.gatewaySerial!, deviceIds).subscribe(configs => {
      if (configs == null) {
        configs = [];
      }

      const addedConfigs:OPCUAConfig[] = [];
      const updatedConfigs:OPCUAConfig[] = [];
      const removedConfigs:OPCUAConfig[] = [];

      const undeployedConfigs = configs.filter(c => c.isDeployed != true);
      for (let config of undeployedConfigs) {
        if (config.actionType == "add") {
          addedConfigs.push(config);
        } else if (config.actionType == "update") {
          updatedConfigs.push(config);
        } else if (config.actionType == "delete") {
          removedConfigs.push(config);
        }
      }

      const dialogRef = this.dialog.open(OpcuaPreviewChangesComponent, {
        panelClass: 'dialog-container-custom',
        disableClose: true,
        data: {
          addedConfigs: addedConfigs,
          updatedConfigs: updatedConfigs,
          removedConfigs: removedConfigs,
          removedDevices: this.devices.data.filter(d => d.isDeleted),
          serial: this.gatewaySerial,
          devices: this.devices.data,
        },
        width: '850px',
        height: '910px',
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getOPCUADevices();
        }
      });
    });
  }

  openDeletionDialog(device: OPCUADevice) {
    const dialogRef = this.dialog.open(DeleteOpcuaDeviceDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        serial: this.gatewaySerial,
        device: device,
      },
      width: '792px',
      height: '764px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.success) {
        this.notificationService.success('mbus-delete-device.snackbar.success');

        const deletedDevice = this.devices.data.find(d => d.id == device.id);
        if(deletedDevice) {
          deletedDevice.isDeleted = true;
          deletedDevice.hasUndeployedDataPoints = true;
        }
      }
    })
  }

  openCSVLabelsDialog(device: OPCUADevice) {
    const dialogRef = this.dialog.open(OpcuaEditCsvLabelsDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.gatewaySerial,
        device: device,
      },
      width: '80%',
      height: '787px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      console.log(response);
      switch (response.status) {
        case "200":
          console.log(response);
          this.notificationService.success( 'gateway-details.snackbar.mbus-device-csv-labels-update.success',
            {updatedDatapoints: response.updatedDatapoints});
          break;
        case "409":
          console.log(response);
          this.notificationService.failure('gateway-details.snackbar.mbus-device-csv-labels-update.error',
            {updatedDatapoints: 0});
          break;
        case "closed":
          break;
        default:
          this.notificationService.failure('gateway-details.snackbar.device-update.error');
      }
    });
  }

  private setDeviceDataPoints() {
    for(let i = 0; i < this.devices.data.length; i++) {
      const device = this.devices.data[i];
      this.deviceDataPoints[device.id] = 0;

      this.opcuaDeviceService.getEIoTDataPoints(this.gatewaySerial!!, device.id).subscribe(scanResult => {
        this.deviceDataPoints[device.id] = scanResult.length;
      });
    }
  }
}
