import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {NetworkInterfaceConfiguration} from "../domain/network-interface-configuration";


@Injectable({
  providedIn: 'root'
})
export class NetworkInterfaceConfigurationService {

  constructor(private http: HttpClient) {
  }

  public getNetworkInterfaceConfiguration(serial: string): Observable<NetworkInterfaceConfiguration> {
    return this.http.get<NetworkInterfaceConfiguration>('/api/config/' + serial + '/networkConfiguration') as Observable<NetworkInterfaceConfiguration>;
  }

  public updateNetworkInterfaceConfiguration(networkConfig: NetworkInterfaceConfiguration, serial: string): Observable<NetworkInterfaceConfiguration> {
    return this.http.post<NetworkInterfaceConfiguration>('/api/config/' + serial + '/networkConfiguration', networkConfig) as Observable<NetworkInterfaceConfiguration>;
  }

}
