import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../../services/notification.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { OPCUAConfig } from '../../../../domain/opcua-config';
import { OPCUADevice } from '../../../../domain/opcua-device';
import { OPCUADeviceService } from '../../../../services/opcua-device.service';

@Component({
  selector: 'eis-gateway-opcua-preview-changes',
  templateUrl: './opcua-preview-changes.component.html',
  styleUrls: ['./opcua-preview-changes.component.scss']
})
export class OpcuaPreviewChangesComponent implements OnInit {

  public addedConfigs = new MatTableDataSource<OPCUAConfig>([]);
  public updatedConfigs = new MatTableDataSource<OPCUAConfig>([]);
  public removedConfigs = new MatTableDataSource<OPCUAConfig>([]);
  public removedDevices = new MatTableDataSource<OPCUADevice>([]);
  public displayedColumns: string[] = ['id', 'manufacturer', 'type', 'portName']
  public removedDevicesDisplayedColumns: string[] = ['deviceName', 'serverAddress', 'portNumber']
  public deleteDataPointsInIoT = true;
  public activeTabIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { addedConfigs: OPCUAConfig[], updatedConfigs: OPCUAConfig[], removedConfigs: OPCUAConfig[], removedDevices: OPCUADevice[], devices: OPCUADevice[], serial: string},
    public dialogRef: MatDialogRef<OpcuaPreviewChangesComponent>,
    private notificationService: NotificationService,
    private opcuaDeviceService: OPCUADeviceService
  ) { }

  ngOnInit(): void {
    this.data.addedConfigs = this.sortAfterSerialNumberAndKey(this.data.addedConfigs);
    this.data.updatedConfigs = this.sortAfterSerialNumberAndKey(this.data.updatedConfigs);
    this.data.removedConfigs = this.sortAfterSerialNumberAndKey(this.data.removedConfigs);

    this.addedConfigs = new MatTableDataSource<OPCUAConfig>(this.data.addedConfigs);
    this.updatedConfigs = new MatTableDataSource<OPCUAConfig>(this.data.updatedConfigs);
    this.removedConfigs = new MatTableDataSource<OPCUAConfig>(this.data.removedConfigs);
    this.removedDevices = new MatTableDataSource<OPCUADevice>(this.data.removedDevices);
  }

  applyChanges() {
    this.opcuaDeviceService.applyOPCUAConfig(this.data.serial, this.deleteDataPointsInIoT).subscribe(
      {
        next: (response) => {
          this.dialogRef.close(true);
          this.notificationService.success("mbus-preview-changes.apply.success");
        },
        error: (err) => {
          let errorMessage = "mbus-preview-changes.apply.error";
          if(typeof  err.error == "string") {
            errorMessage = err.error;
          }
          this.notificationService.failure(errorMessage);
        }
      }
    );
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  private sortAfterSerialNumberAndKey(configs: OPCUAConfig[]): OPCUAConfig[] {
    return configs;
  }

  selectedTabChange(changeEvent: MatTabChangeEvent) {
    this.activeTabIndex = changeEvent.index;
  }

}
