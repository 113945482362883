<div fxLayout="column" class="main-container">
  <div class="device-header-container">
    <div class="action-container">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <mat-form-field class="comport-select-field input-field" *ngIf="availablePorts.length > 0">
          <mat-label>COM-Port</mat-label>
          <mat-select [formControl]="selectedPortControl">
            <mat-option *ngFor="let port of availablePorts" [value]="port">
              {{port.portName}} - {{port.baudRate}} baud
            </mat-option>
          </mat-select>
        </mat-form-field>
        <eon-ui-button [text]="'mbus-device-table.buttons.scan' | translate" *ngIf="!scanningDevices"
                       icon="search" scheme="red"
                       (click)="scanMBusDevices()"
                       [disabled]="!selectedPortControl.value">
        </eon-ui-button>
        <eon-ui-button [text]="'mbus-device-table.buttons.scan-stop' | translate" *ngIf="scanningDevices"
                       icon="close" scheme="red"
                       (click)="showStopMBusScanDialog()">
        </eon-ui-button>
        <eon-ui-button [text]="'mbus-device-table.buttons.add-manually' | translate"
                       icon="add" scheme="red" rank="secondary"
                       (click)="addDeviceManually()"
                       [disabled]="!selectedPortControl.value">
        </eon-ui-button>
      </div>
      <eon-ui-button [text]="'mbus-device-table.buttons.preview-changes' | translate" *ngIf="hasChanges()"
                     icon="meter_read_no_read"
                     scheme="red"
                     rank="secondary"
                     (click)="previewUndeployedChanges()"></eon-ui-button>
    </div>
    <div class="scan-info" *ngIf="deviceScanStartDate">
      <div>
        <span>{{'mbus-device-table.labels.devices.count' | translate: {deviceCount: devices.length} }}</span>&nbsp;
        <span *ngIf="scanError" class="error"> {{"mbus-device-table.labels.devices.scan-error" | translate:{message: scanError} }}</span>
        <span *ngIf="errorDevices.length > 0 && !ignoreDeviceErrors" class="error-devices-container">
          <eon-ui-link icon="alert" size="small" (click)="showErrorDevicesInDialog()" text="{{'mbus-device-table.error-device-available' | translate: {count: errorDevices.length} }}"></eon-ui-link>
        </span>
      </div>
      <div>
        <span *ngIf="!deviceScanEndDate">{{'mbus-device-table.labels.devices.scan-start-date' | translate: {startDate: deviceScanStartDate | date:'dd.MM.yyyy HH:mm'} }}</span>
        <span *ngIf="deviceScanEndDate">{{'mbus-device-table.labels.devices.scan-start-end-date' | translate: {startDate: deviceScanStartDate | date:'dd.MM.yyyy HH:mm', endDate: deviceScanEndDate | date:'dd.MM.yyyy HH:mm'} }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="scanningDevices" class="warning-container">
    <eon-ui-preloader-circle size="small"></eon-ui-preloader-circle>
    <span>{{'mbus-device-table.warning.scan-take-long' | translate}}</span>
  </div>

  <div class="table-container">
    <table mat-table matSort [dataSource]="devicesDataSource">
      <ng-container matColumnDef="hasUndeployedDataPoints">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="hasUndeployedDataPoints">{{'mbus-device-table.header.has-undeployed-data-points' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">
          <div *ngIf="device.hasUndeployedDataPoints">
            <mat-icon class="red-point">circle</mat-icon>
          </div>
          <div *ngIf="!device.manufacturer && getDataPointCount(device.id) == 0">
            <mat-icon class="green-point">circle</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="id">{{'mbus-device-table.header.serial-number' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{device.id}}</td>
      </ng-container>

      <ng-container matColumnDef="manufacturer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="manufacturer">{{'mbus-device-table.header.manufacturer' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{device.manufacturer}}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="type">{{'mbus-device-table.header.type' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{device.type}}</td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="version">{{'mbus-device-table.header.version' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{device.version}}</td>
      </ng-container>

      <ng-container matColumnDef="portName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="portName">Port</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{ device.portName}}</td>
      </ng-container>

      <ng-container matColumnDef="dataPointCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="dataPointCount">{{'mbus-device-table.header.data-point-count' | translate}}</th>
        <td mat-cell *matCellDef="let device"
            [matTooltip]="'mbus-device-table.row.title' | translate"
            [matTooltipShowDelay]="500">{{getDataPointCount(device.id)}}</td>
      </ng-container>

      <ng-container matColumnDef="contextActions">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
        <td mat-cell *matCellDef="let device" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{device: device}">
          <mat-icon class="clickable">more_vert</mat-icon>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        (click)="deviceClicked(row)"
        [class.active]="selectedDevice?.id == row.id"
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div *ngIf="port && devices.length == 0 && !scanningDevices" class="warning-container">
    {{'mbus-device-table.warning.no-devices' | translate : {portName: port.portName} }}
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-device=device>
    <a mat-menu-item target="_blank"
       href="https://app.iot.eon.com/tenants/{{tenant}}/devices/{{gatewaySerial}}.{{device.id}}"
       [disabled]="getDataPointCount(device.id) == 0">
      <mat-icon svgIcon="link-external"></mat-icon>
      {{'device-table.popupmenu.eiot' | translate}}
    </a>

    <a mat-menu-item target="_blank"
       href="https://monitoring.eon.com/de-DE/app/s187_sonic_digital_twin/search?q=search%20source%3D%22http%3Ahttp_sonic_digital_twin_s187_eis_gateway%22%7C%20search%20serialnumber%3D%22{{gatewaySerial}}%22%20{{device.id}}">
      <mat-icon svgIcon="link-external"></mat-icon>
      {{'device-table.popupmenu.log' | translate}}
    </a>

    <button mat-menu-item (click)="openDeletionDialog(device)" [disabled]="device.isDeleted">
      <mat-icon svgIcon="eon-trash-bin"></mat-icon>
      {{'device-table.popupmenu.delete' | translate}}
    </button>

    <button mat-menu-item (click)="openEditLabelDialog(device)">
      <mat-icon>edit</mat-icon>
      {{'mbus-device-table.popupmenu.edit-labels' | translate}}
    </button>

    <button mat-menu-item (click)="deviceClicked(device)">
      <mat-icon>edit</mat-icon>
      {{'device-table.popupmenu.csv-edit' | translate}}
    </button>

    <button mat-menu-item (click)="openCSVLabelsDialog(device)">
      <mat-icon>edit</mat-icon>
      {{'device-table.popupmenu.csv-label-edit' | translate}}
    </button>
  </ng-template>
</mat-menu>
