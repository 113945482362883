import { Component, Inject, OnInit } from '@angular/core';
import { OPCUADataPoint } from '../../../../domain/opcua-data-point';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OPCUADevice } from '../../../../domain/opcua-device';
import { OPCUADeviceService } from '../../../../services/opcua-device.service';
import { DynamicFlatNode } from '../../../../domain/dynamic-flat-node';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'eis-gateway-opcua-unit-browser-dialog',
  templateUrl: './opcua-unit-browser-dialog.component.html',
  styleUrls: ['./opcua-unit-browser-dialog.component.scss']
})
export class OpcuaUnitBrowserDialogComponent implements OnInit {
  public loadingDataPoints: boolean = true;
  public displayedColumns: string[] = ['name', 'value', 'actions'];
  public dataPoints: OPCUADataPoint[] = [];
  sampleJson = {
    name: "John",
    age: 30,
    address: {
      street: "123 Main St",
      city: "New York",
      postalCode: "10001"
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {device: OPCUADevice, parentNode: OPCUADataPoint, activeSerial: string},
    public dialogRef: MatDialogRef<OpcuaUnitBrowserDialogComponent>,
    private deviceService: OPCUADeviceService,
  ) { }

  ngOnInit(): void {
    this.loadingDataPoints = true;
    this.deviceService.browseChildren(this.data.device, this.data.parentNode.node_id).subscribe(async children=> {
      if(children == null) {
        children = [];
      }

      const childrenDataPoints: OPCUADataPoint[] = [];
      for (let child of children) {
        const childDataPoints = await firstValueFrom(this.deviceService.getDataPoints(this.data.device, child.node_id));
        childrenDataPoints.push(...childDataPoints);
      }

      this.dataPoints = childrenDataPoints;
      this.loadingDataPoints = false;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  applyUnit(dataPoint: OPCUADataPoint) {
    this.dialogRef.close({unit: dataPoint.value});
  }

  unitApplied(unit: string) {
    this.dialogRef.close({unit: unit});
  }

  resetUnit() {
    this.dialogRef.close({unit: 'none'});
  }
}
