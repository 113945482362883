import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'eis-gateway-label-chips',
  templateUrl: './label-chips.component.html',
  styleUrls: ['./label-chips.component.scss']
})
export class LabelChipsComponent implements OnInit {

  @Input()
  labels: String = "";

  labelsChips: Array<{key:string, value: string}> = [];

  constructor() { }

  ngOnInit(): void {
    this.labelsChips = this.labels?.split(",").map(labels => {
      const parts = labels.split(":")
      return {
        key: parts[0],
        value: parts.slice(1).join(":")
      }
    }) ?? [];
  }

}
