import { Component, OnInit } from '@angular/core';
import { EonIotService } from '../../services/eon-iot.service';
import { Announcement } from '../../domain/announcement';
import { Observable } from 'rxjs';

@Component({
  selector: 'eis-gateway-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {

  public announcements: Array<Announcement> = [];
  constructor(
    private eiotService: EonIotService
  ) { }

  ngOnInit(): void {
    this.eiotService.getAnnouncements().subscribe(announcements => {
      this.announcements = this.filterDismissedAnnouncements(announcements);
    })
  }

  dismissAnnouncement(announcement: Announcement) {
    let dismissedAnnouncementIds = localStorage.getItem("dismissed-announcement-ids") ?? "";
    dismissedAnnouncementIds += "," + announcement.id;

    localStorage.setItem("dismissed-announcement-ids", dismissedAnnouncementIds);
    this.announcements = this.filterDismissedAnnouncements(this.announcements);
  }

  private filterDismissedAnnouncements(announcements: Array<Announcement>) : Array<Announcement> {
    let dismissedAnnouncementIds = localStorage.getItem("dismissed-announcement-ids") ?? "";
    return announcements.filter(a => dismissedAnnouncementIds.indexOf(a.id) == -1)
  }
}
