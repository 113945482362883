import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OSVersionService } from '../../../../services/os-version.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { GatewayOSVersion } from '../../../../domain/gateway-osversion.interface';

@Component({
  selector: 'eis-gateway-add-version-dialog',
  templateUrl: './add-version-dialog.component.html',
  styleUrls: ['./add-version-dialog.component.scss']
})
export class AddVersionDialogComponent implements OnInit {
  public osVersion: GatewayOSVersion;
  public version: FormControl<string | null>;
  public formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {osVersion: GatewayOSVersion},
              public dialogRef: MatDialogRef<AddVersionDialogComponent>,
              private osVersionService: OSVersionService) {
    this.osVersion = data.osVersion;
  }

  ngOnInit(): void {
    this.version = new FormControl(this.osVersion?.latestVersion ?? '', [Validators.required]);

    this.formGroup = new FormGroup<any>({
      latestVersion: this.version,
    });
  }

  update() {
    if(this.formGroup.valid) {
      if(this.osVersion) {
        this.osVersion.latestVersion = this.version.value!;
        this.osVersionService.updateVersion(this.osVersion).subscribe({
          next: (_) => {
            this.dialogRef.close({status: 200});
          },
          error: (err) => this.dialogRef.close(err)
        });
      } else{
        const conversion = {
          latestVersion: this.version.value!,
        } as GatewayOSVersion;

        this.osVersionService.createVersion(conversion).subscribe({
          next: (_) => {
            this.dialogRef.close({status: 200});
          },
          error: (err) => this.dialogRef.close(err)
        });
      }
    } else {
      this.markAsTouched(this.formGroup);
    }
  }

  markAsTouched(formGroup: FormGroup) {
    formGroup.markAsTouched({ onlySelf: true });
    Object.keys(formGroup.controls).map((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl || control instanceof FormArray || control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }
}
