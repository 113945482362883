import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { OPCUADevice } from '../../../../domain/opcua-device';
import { OPCUADeviceService } from '../../../../services/opcua-device.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'eis-gateway-add-opcua-server-dialog',
  templateUrl: './add-opcua-device-dialog.component.html',
  styleUrls: ['./add-opcua-device-dialog.component.scss']
})
export class AddOpcuaDeviceDialogComponent implements OnInit {
  public nameControl: FormControl<string | null>;
  public labelsControl: FormControl<string | null>;
  public serverAddressControl: FormControl<string | null>;
  public portNumberControl: FormControl<string | null>;
  public userNameControl: FormControl<string | null>;
  public passwordControl: FormControl<string | null>;
  public certificateControl: FormControl<string | null>;
  public privateKeyControl: FormControl<string | null>;
  public authTypeControl: FormControl<string | null>;
  public formGroup: FormGroup;
  public isVerified: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {device: OPCUADevice, activeSerial: string},
    public dialogRef: MatDialogRef<AddOpcuaDeviceDialogComponent>,
    private opcuaDeviceService: OPCUADeviceService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.nameControl = new FormControl( '', [Validators.required]);
    this.labelsControl = new FormControl( '', );
    this.serverAddressControl = new FormControl( '', [Validators.required]);
    this.portNumberControl = new FormControl( '', [Validators.required]);
    this.userNameControl = new FormControl( '', []);
    this.passwordControl = new FormControl( '', []);
    this.certificateControl = new FormControl( '', []);
    this.privateKeyControl = new FormControl( '', []);
    this.authTypeControl = new FormControl( 'anonymous', []);

    this.userNameControl.disable();
    this.passwordControl.disable();
    this.certificateControl.disable();
    this.privateKeyControl.disable();

    this.formGroup = new FormGroup<any>({
      name: this.nameControl,
      labels: this.labelsControl,
      serverAddress: this.serverAddressControl,
      portNumber: this.portNumberControl,
      userName: this.userNameControl,
      password: this.passwordControl,
      certificate: this.certificateControl,
      privateKey: this.privateKeyControl,
      authType: this.authTypeControl
    });

    this.authTypeControl.valueChanges.subscribe(type => {
      if (type == "anonymous") {
        this.userNameControl.disable();
        this.passwordControl.disable();
        this.certificateControl.disable();
        this.privateKeyControl.disable();
      } else if (type == "basic") {
        this.userNameControl.enable();
        this.passwordControl.enable();
        this.certificateControl.disable();
        this.privateKeyControl.disable();
      } else {
        this.certificateControl.enable();
        this.privateKeyControl.enable();
        this.userNameControl.disable();
        this.passwordControl.disable();
      }
    });

    this.serverAddressControl.valueChanges.subscribe(val => {
      this.isVerified = false;
    });
    this.portNumberControl.valueChanges.subscribe(val => {
      this.isVerified = false;
    });
    this.userNameControl.valueChanges.subscribe(val => {
      this.isVerified = false;
    });
    this.passwordControl.valueChanges.subscribe(val => {
      this.isVerified = false;
    });
  }

  validateAndUpdate() {
    if(this.formGroup.valid) {
      const device = this.formGroup.getRawValue() as OPCUADevice;
      device.gatewaySerial = this.data.activeSerial;

      this.opcuaDeviceService.addDevice(device).subscribe({
        next: (response) => this.dialogRef.close({status: "200"}),
        error: (err) => {
          this.notificationService.failureMessage(err.error);
        }
      })
    } else {
      this.markAsTouched(this.formGroup);
    }
  }

  markAsTouched(formGroup: FormGroup) {
    formGroup.markAsTouched({ onlySelf: true });
    Object.keys(formGroup.controls).map((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl || control instanceof FormArray || control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  verifyConnection() {
    if(this.formGroup.valid) {
      const device = this.formGroup.getRawValue() as OPCUADevice;
      device.gatewaySerial = this.data.activeSerial;

      this.opcuaDeviceService.verifyDeviceConnection(device).subscribe(res => {
        this.isVerified = res;
        if(res) {
          this.notificationService.success("add-opcua-server-dialog.verify.success");
        } else {
          this.notificationService.failure("add-opcua-server-dialog.verify.error");
        }
      });
    } else {
      this.markAsTouched(this.formGroup);
    }
  }
}
