import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { OPCUAConfig } from '../../../../domain/opcua-config';
import { OPCUADevice } from '../../../../domain/opcua-device';

@Component({
  selector: 'eis-gateway-opcua-config-table',
  templateUrl: './opcua-config-table.component.html',
  styleUrls: ['./opcua-config-table.component.scss']
})
export class OpcuaConfigTableComponent {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public configs: MatTableDataSource<OPCUAConfig>;

  @Output()
  public updateDatasource = new EventEmitter<string>();

  @Input()
  removedDevices?: OPCUADevice[];

  @Input()
  public devices: OPCUADevice[] = [];

  public displayedColumns: string[] = ['deviceName', 'parentName', 'browseName', 'pollingFrequency']

  constructor() {
  }

  getDevice(config: OPCUAConfig) : OPCUADevice | undefined {
    return this.devices.find(d => d.id == config.deviceId);
  }
}
