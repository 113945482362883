import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { DynamicFlatNode } from './dynamic-flat-node';
import { BehaviorSubject, firstValueFrom, map, merge, Observable } from 'rxjs';
import { OPCUATreeNode } from './opcua-tree-node';
import { OPCUADevice } from './opcua-device';
import { FlatTreeControl } from '@angular/cdk/tree';
import { OPCUADeviceService } from '../services/opcua-device.service';

export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);
  private cachedChildren = new Map<string, OPCUATreeNode[]>();

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private device: OPCUADevice,
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private _deviceService: OPCUADeviceService,
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  async toggleNode(node: DynamicFlatNode, expand: boolean) {
    if(!node.expandable) {
      return;
    }

    const index = this.data.indexOf(node);

    node.isLoading = expand;

    if (expand) {
      let children = this.cachedChildren.get(node.id);
      if(!children) {
        children = await firstValueFrom(this._deviceService.browseChildren(this.device, node.id));
        this.cachedChildren.set(node.id, children);
      }
      if(!children) {
        children = [];
      }

      const nodes = children.map(
        child => new DynamicFlatNode(child.node_id, child.node_name, child.description, node.level + 1, child.has_children, false, child.opcua_node_class, node),
      );
      this.data.splice(index + 1, 0, ...nodes);
      if(nodes.length == 0) {
        node.expandable = false;
      }
    } else {
      let count = 0;
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++ ) {}
      this.data.splice(index + 1, count);
    }

    // notify the change
    this.dataChange.next(this.data);
    node.isLoading = false;
  }
}
