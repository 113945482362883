export class DynamicFlatNode {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public level = 1,
    public expandable = false,
    public isLoading = false,
    public nodeClass: string,
    public parent: DynamicFlatNode | null = null,
  ) {}
}
