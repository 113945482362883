import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import 'dayjs/locale/de'
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { GatewayDeployment } from "../../../domain/gateway-deployment.interface";
import { MatDialog } from "@angular/material/dialog";
import { DeleteGatewayDialogComponent } from "../delete-gateway-dialog/delete-gateway-dialog.component";
import { NotificationService } from "../../../services/notification.service";
import { ChangeLocationDialogComponent } from "../change-location-dialog/change-location-dialog.component";
import { ImageOSVersion } from '../../../domain/image-osversion.interface';

@Component({
  selector: 'eis-gateway-gateway-table',
  templateUrl: './gateway-table.component.html',
  styleUrls: ['./gateway-table.component.scss']
})
export class GatewayTableComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  public gateways: MatTableDataSource<GatewayDeployment>;

  @Input()
  public imageOsVersions: ImageOSVersion;

  @Input()
  public tenant: String

  @Output()
  public updateDatasource = new EventEmitter<string>();

  @Output()
  public menuVisibilityChanged = new EventEmitter<boolean>();

  public displayedColumns: string[] = [
    'online', 'location', 'gatewaySerial', 'osVersion', 'imageVersion', 'lastContact', 'lastUpdated', 'detailsAction'
  ];

  constructor(private router: Router,
              private dialog: MatDialog,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.gateways.sortingDataAccessor = (data: any, sortHeaderId: string): any => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
  }

  ngAfterViewInit(): void {
    this.gateways.sort = this.sort;
  }

  refresh(): void {
    this.table.renderRows();
  }

  routeToDetails(gateway: GatewayDeployment): void {
    this.router.navigate(['/gateway/' + gateway.gatewaySerial]);
  }

  openDeletionDialog(gatewayId: string): void {
    const dialogRef = this.dialog.open(DeleteGatewayDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        gatewayId: gatewayId,
      },
      width: '792px',
      height: '764px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.updateDatasource.emit();
      if (result.gridxDeleted && result.eiotDeleted) {
        this.notificationService.success('delete-gateway.snackbar.success');
      }
      else if (!result.gridxDeleted && result.eiotDeleted) {
        this.notificationService.failure('delete-gateway.snackbar.success.eiot');
      }
      else if (result.gridxDeleted && !result.eiotDeleted) {
        this.notificationService.failure('delete-gateway.snackbar.success.gridx');
      }
    })
  }

  openChangeDialog(gatewayId: string, tenant: string, location: string): void {
    const dialogRef = this.dialog.open(ChangeLocationDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        gatewayId: gatewayId,
        tenant: tenant,
        location: location
      },
      width: '792px',
      height: '764px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.updateDatasource.emit();
      if (result) {
        this.notificationService.success('change-gateway.snackbar.success');
      }
    })
  }

  openGatewayConfig(gatewaySerial: string) {
    this.router.navigate(['gateway/' + gatewaySerial + '/config/network']);
  }

  menuOpened(opened: boolean) {
    this.menuVisibilityChanged.emit(opened);
  }
}
