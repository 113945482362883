<div *ngIf="announcements" class="announcement-container">
  <div *ngFor="let announcement of announcements" class="announcement">
    <label>
      <span class="bullhorn"></span>
      <span [innerHTML]="announcement.message"></span>
    </label>
    <eon-ui-link hide-icon="true"
                 (click)="dismissAnnouncement(announcement)"
                 size="small"
                 [text]="'Dismiss'"></eon-ui-link>
  </div>
</div>
