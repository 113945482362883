<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div
    class="headline">{{ (device == null ? 'add-device-dialog.headline.add' : 'add-device-dialog.headline.update') | translate}}</div>
</div>

<ng-container *ngIf="step == 1">
  <mat-dialog-content fxFlex class="dialog-content">
    <div>
      <div fxLayout="column">
        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="10px">
              <div fxFlex="50">
                <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                  <mat-label>{{'add-device-dialog.input.device-name.label' | translate}}</mat-label>
                  <input [formControl]="nameInput"
                         matInput
                         class="eon-style-input"
                         type="text"
                  >
                  <mat-error
                    *ngIf="nameInput.invalid">{{'add-device-dialog.input.device-name.error' | translate}}</mat-error>
                  <mat-hint align="start">{{'add-device-dialog.input.device-name.hint' | translate}}</mat-hint>
                </mat-form-field>
              </div>
              <div fxFlex="30" fxLayoutAlign="start center" class="checkbox-section">
                <mat-checkbox class="aggregate-checkbox" [(ngModel)]="aggregateDisabled">Configure Batchsize manually
                </mat-checkbox>
              </div>
            </div>
            <div fxLayout="row" *ngIf="aggregateDisabled">
              <div fxFlex="30">
                <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                  <mat-label>{{'add-device-dialog.input.device-aggregate-rate.label' | translate}}</mat-label>
                  <input [formControl]="aggregateInput"
                         matInput
                         class="eon-style-input"
                         type="number"
                  >
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxLayout="row">
            <div fxLayout="column" class="eon-style-form-field">
              <div>
                <div class="tooltip-div">
                  <eon-ui-info-icon>
                    <eon-ui-tooltip slot="tooltip">
                      <eon-ui-text
                        text-style="quote">{{'add-gateway-dialog.labels.tooltip.headline' | translate}}</eon-ui-text>
                      <eon-ui-text
                        text-style="subheadline">{{'add-gateway-dialog.labels.tooltip.subheadline' | translate}}</eon-ui-text>
                      <eon-ui-text text-style="abstract">
                        {{'add-gateway-dialog.labels.tooltip.example' | translate}}
                      </eon-ui-text>
                      <eon-ui-text
                        text-style="subheadline">{{'add-gateway-dialog.labels.tooltip.delete-label' | translate}}</eon-ui-text>
                      <eon-ui-text text-style="abstract">
                        {{'add-gateway-dialog.labels.tooltip.delete-example' | translate}}
                      </eon-ui-text>
                    </eon-ui-tooltip>
                  </eon-ui-info-icon>
                  <span>{{'add-device-dialog.input.labels' | translate}}</span>
                </div>
              </div>

              <div fxLayout="column" fxLayoutGap="20px">
                <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                  <input [formControl]="labels"
                         matInput
                         class="eon-style-input"
                         type="text"
                  >
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutAlign="left center">
                <div class="tooltip-div">
                  <eon-ui-info-icon>
                    <eon-ui-tooltip slot="tooltip">
                      <eon-ui-text
                        [innerHTML]="'add-gateway-dialog.data-point-labels.tooltip' | translate"
                        text-style="subheadline"></eon-ui-text>
                    </eon-ui-tooltip>
                  </eon-ui-info-icon>

                  <span class="margin-right-24">{{'add-device-dialog.input.data-point-labels' | translate}}</span>
                  <eon-ui-link (click)="transferLabels()" icon="arrow_down" text="{{'add-device-dialog.input.copy-labels' | translate}}"></eon-ui-link>
                </div>
              </div>

              <div fxLayout="column" fxLayoutGap="20px">
                <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                  <input [formControl]="dataPointLabels"
                         matInput
                         class="eon-style-input"
                         type="text"
                  >
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxLayout="row">
            <div fxLayout="column">
              <div>{{"add-device-dialog.protocol" | translate}}</div>
              <mat-radio-group aria-label="Select an option" [formControl]="protocolAdapterSelection" [disabled]="data.device != null">
                <mat-radio-button [value]="true" class="radio-button">TCP</mat-radio-button>
                <mat-radio-button [value]="false" class="radio-button">RTU</mat-radio-button>
              </mat-radio-group>
            </div>
            <div *ngIf="!this.protocolAdapterSelection.value" class="port-container">
              <mat-form-field class="input-field" *ngIf="availablePorts$ | async as availablePorts">
                <mat-label>COM-Port</mat-label>
                <mat-select [formControl]="comportSelection" [compareWith]="compareFn">
                  <mat-option *ngFor="let port of availablePorts" [value]="port.portName">
                    {{port.portName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" *ngIf="this.protocolAdapterSelection.value">
            <div fxFlex="48">
              <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                <mat-label>{{'add-device-dialog.input.ip-address.label' | translate}}</mat-label>
                <input [formControl]="ipAddressInput"
                       matInput
                       class="eon-style-input"
                       placeholder="XXX.XXX.XXX.XXX"
                       type="text"
                >
                <mat-hint align="start">{{'add-device-dialog.input.ip-address.hint' | translate}}</mat-hint>
                <mat-error
                  *ngIf="ipAddressInput.invalid">{{'add-device-dialog.input.ip-address.error' | translate}}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="4"></div>
            <div fxFlex="48">
              <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                <mat-label>{{'add-device-dialog.input.port-number.label' | translate}}</mat-label>
                <input [formControl]="portNumberInput"
                       matInput
                       class="eon-style-input"
                       type="number"
                >
                <mat-hint align="start">{{'add-device-dialog.input.port-number.hint' | translate}}</mat-hint>
                <mat-error
                  *ngIf="portNumberInput.invalid">{{'add-device-dialog.input.port-number.error' | translate}}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="48">
              <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
                <mat-label>{{'add-device-dialog.input.unit-id.label' | translate}}</mat-label>
                <input [formControl]="unitIdInput"
                       matInput
                       class="eon-style-input"
                       type="number"
                >
                <mat-hint align="start">{{'add-device-dialog.input.unit-id.hint' | translate}}</mat-hint>
                <mat-error
                  *ngIf="unitIdInput.invalid">{{'add-device-dialog.input.unit-id.error' | translate}}</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="4"></div>
            <div fxFlex="48" *ngIf="!device">
              <eis-gateway-upload fileType=".csv" (change)="fileChange($event)"></eis-gateway-upload>
            </div>

          </div>

        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="red" rank="secondary" icon="close" text="{{'general.cancel' | translate}}"
                   [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>
    <eon-ui-button *ngIf="!device" (click)="checkDevice()" [disabled]="!checkEnabled"
                   text="{{'general.button.check-process' | translate}}"></eon-ui-button>
    <eon-ui-button *ngIf="device" (click)="validateAndUpdate()" [disabled]="!checkEnabled"
                   text="{{'general.button.update' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>


<ng-container *ngIf="step == 2">
  <mat-dialog-content fxFlex>
    <div *ngIf="checkDeviceErrors.length > 0">
      <p class="stepheadline">{{'add-device-dialog.datapoints.error' | translate}}</p>
      <p>{{checkDeviceErrors.length}} Errors</p>
      <div fxLayout="column">
        <p class="stepsubheadline" *ngIf="validationDeviceErrors.length > 0">{{'add-device-dialog.validation.error' | translate}}</p>
        <ul>
          <li *ngFor="let error of validationDeviceErrors">
            {{error.message}}
          </li>
        </ul>

        <p class="stepsubheadline" *ngIf="csvDeviceErrors.length > 0">{{'add-device-dialog.csv.error' | translate}}</p>
        <ul>
          <li *ngFor="let error of csvDeviceErrors">
            <div><span>Line: </span>{{error.position}}</div>
            <div><span>Element hint: </span>{{error.elementHint}}</div>
            <div><span>Message: </span><span class="error-message" [innerHTML]="error.message"></span></div>
          </li>
        </ul>
      </div>
    </div>


    <div *ngIf="checkDeviceErrors.length == 0">
      <p class="stepheadline">{{'add-device-dialog.datapoints.success' | translate}}</p>

      <div fxLayout="column" fxLayoutGap="35px">

      </div>
      <div fxLayout="column" fxLayoutGap="35px">
        <eis-gateway-checkmark></eis-gateway-checkmark>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <div fxLayout="row" fxLayoutGap="35px" style="align-items:center">
      <eon-ui-link icon="chevron_small_left"
                   text="{{'general.back' | translate}}"
                   (click)="backToForm()"
                   *ngIf="checkDeviceErrors.length > 0"></eon-ui-link>

      <eon-ui-button scheme="red" rank="secondary" icon="close" text="{{'general.cancel' | translate}}"
                     [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>
    </div>

    <div fxLayout="row" fxLayoutGap="35px">
      <eon-ui-button scheme="red" rank="secondary" (click)="copyErrorToClipboard()" text="{{'general.copy-to-clipboard' | translate}}"
                   *ngIf="checkDeviceErrors.length > 0"></eon-ui-button>

    </div>
    <eon-ui-button (click)="confirmDevice()" text="{{'general.button.complete-process' | translate}}"
                   *ngIf="checkDeviceErrors.length == 0"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>
