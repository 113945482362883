<div class="table-container">
  <table mat-table matSort [dataSource]="datapoints">
    <ng-container matColumnDef="online">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'gateway-table.header.online-status' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">
        <mat-icon *ngIf="datapoint.icConnected" class="wifi-on">wifi</mat-icon>
        <mat-icon *ngIf="!datapoint.icConnected" class="wifi-off">wifi_off</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="secondaryAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="secondaryAddress">{{'mbus-device-table.header.serial-number' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.secondaryAddress}}</td>
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="key">{{'mbus-datapoint-configurator.header.key' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.key}}</td>
    </ng-container>

    <ng-container matColumnDef="dataType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="data_type">{{'mbus-datapoint-configurator.header.data-type' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.data_type}}</td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="value">{{'mbus-datapoint-configurator.header.value' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.lastValue !== null ? (datapoint.lastValue | number : '1.0-2') : 'n/a'}}</td>
    </ng-container>

    <ng-container matColumnDef="unit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="unit">{{'mbus-datapoint-configurator.header.unit' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.unit}}</td>
    </ng-container>

    <ng-container matColumnDef="pollingFrequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="pollingFrequency">{{'mbus-config-table.header.polling-frequency' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.pollingInterval}}min</td>
    </ng-container>

    <ng-container matColumnDef="lastUpdated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.lastUpdated' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.lastUpdated | date:'dd.MM.yyyy HH:mm'}}</td>
    </ng-container>

    <ng-container matColumnDef="labels">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'datapoint-table.header.labels' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint" (click)="copyLabelsToClipboard(datapoint.labels)" matTooltip="Click to copy labels to clipboard" matTooltipClass="label-tooltip">
        <eis-gateway-label-chips [labels]="datapoint.labels"></eis-gateway-label-chips>
      </td>
    </ng-container>

    <ng-container matColumnDef="contextActions">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
      <td mat-cell *matCellDef="let datapoint" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{datapoint: datapoint}">
        <mat-icon class="clickable">more_vert</mat-icon>
      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-datapoint=datapoint>
    <a mat-menu-item target="_blank"href="https://app.iot.eon.com/tenants/{{tenant}}/datapoints/{{datapoint.datapointId}}">
      <mat-icon svgIcon="link-external"></mat-icon>
      {{'datapoint-table.popupmenu.eiot' | translate}}
    </a>

  </ng-template>
</mat-menu>
