<div mat-dialog-title class="dialog-title">
  <h1 class="headline">{{'mbus-preview-changes.header' | translate }}</h1>

  <eon-ui-icon fxLayout fxLayoutAlign="end" (click)="closeDialog()" class=closeX name="close"></eon-ui-icon>
</div>

<mat-dialog-content fxFlex class="dialog-content">
  <div>
    <mat-tab-group  (selectedTabChange)="selectedTabChange($event)" dynamicHeight mat-stretch-tabs animationDuration="200ms" class="tab-group">
      <mat-tab>
        <ng-template mat-tab-label>
          <span matBadge="{{addedConfigs.data.length}}" matBadgeOverlap="false">{{'mbus-preview-changes.tabs.added-data-points' | translate}}</span>
        </ng-template>
        <eis-gateway-opcua-config-table [configs]="addedConfigs"
                                        [devices]="data.devices"
        ></eis-gateway-opcua-config-table>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span matBadge="{{updatedConfigs.data.length}}" matBadgeOverlap="false">{{'mbus-preview-changes.tabs.updated-data-points' | translate}}</span>
        </ng-template>
        <eis-gateway-opcua-config-table [configs]="updatedConfigs"
                                        [devices]="data.devices"
        ></eis-gateway-opcua-config-table>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span matBadge="{{removedConfigs.data.length}}" matBadgeOverlap="false">{{'mbus-preview-changes.tabs.removed-data-points' | translate}}</span>
        </ng-template>
        <div class="datapoint-table">
          <eis-gateway-opcua-config-table [configs]="removedConfigs"
                                          [removedDevices]="removedDevices.data"
                                          [devices]="data.devices"
          ></eis-gateway-opcua-config-table>
        </div>
      </mat-tab>
      <mat-tab *ngIf="removedDevices.data.length > 0">
        <ng-template mat-tab-label>
          <span matBadge="{{removedDevices.data.length}}" matBadgeOverlap="false">{{'mbus-preview-changes.tabs.removed-devices' | translate}}</span>
        </ng-template>
        <div class="table-container">
          <table mat-table matSort [dataSource]="removedDevices">
            <ng-container matColumnDef="deviceName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="deviceName">{{'device-table.header.device-name' | translate}}</th>
              <td mat-cell *matCellDef="let device">{{device.name}}</td>
            </ng-container>

            <ng-container matColumnDef="serverAddress">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="manufacturer">{{'device-table.header.ip-address' | translate}}</th>
              <td mat-cell *matCellDef="let device">{{device.serverAddress}}</td>
            </ng-container>

            <ng-container matColumnDef="portNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="type">{{'device-table.header.port-number' | translate}}</th>
              <td mat-cell *matCellDef="let device">{{device.portNumber}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="removedDevicesDisplayedColumns; sticky: true"></tr>
            <tr
              mat-row
              title="{{'mbus-device-table.row.title' | translate}}"
              class="device-row"
              *matRowDef="let row; columns: removedDevicesDisplayedColumns;"></tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: space-between;">
  <eon-ui-button scheme="redInverted"
                 icon="close"
                 text="{{'general.cancel' | translate}}"
                 (click)="closeDialog()"></eon-ui-button>

  <eon-ui-checkbox *ngIf="removedConfigs.data.length > 0"
                   [(ngModel)]="deleteDataPointsInIoT"
                   label="{{'mbus-preview-changes.delete-data-points-iot' | translate}}"></eon-ui-checkbox>

  <eon-ui-button [text]="'mbus-preview-changes.button.deploy' | translate"
                 (click)="applyChanges()"></eon-ui-button>
</mat-dialog-actions>
