<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div class="headline">{{'add-opcua-server-dialog.title' | translate}}</div>
</div>

<div mat-dialog-content fxFlex class="dialog-content" [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutGap="12px">
    <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
      <mat-label>{{'add-opcua-server-dialog.input.name' | translate}}</mat-label>
      <input [formControl]="nameControl"
             matInput
             class="eon-style-input"
             type="text"
      >
      <mat-hint align="start">{{'add-opcua-server-dialog.input.name.hint' | translate}}</mat-hint>
    </mat-form-field>

    <div fxLayout="column">
      <div class="radio-button-headline">
        <div class="tooltip-div">
          <eon-ui-info-icon>
            <eon-ui-tooltip slot="tooltip">
              <eon-ui-text
                text-style="quote">{{'add-gateway-dialog.labels.tooltip.headline' | translate}}</eon-ui-text>
              <eon-ui-text
                text-style="subheadline">{{'add-gateway-dialog.labels.tooltip.subheadline' | translate}}</eon-ui-text>
              <eon-ui-text text-style="abstract">
                {{'add-gateway-dialog.labels.tooltip.example' | translate}}
              </eon-ui-text>
              <eon-ui-text
                text-style="subheadline">{{'add-gateway-dialog.labels.tooltip.delete-label' | translate}}</eon-ui-text>
              <eon-ui-text text-style="abstract">
                {{'add-gateway-dialog.labels.tooltip.delete-example' | translate}}
              </eon-ui-text>
            </eon-ui-tooltip>
          </eon-ui-info-icon>
          <span>{{'add-device-dialog.input.labels' | translate}}</span>
        </div>
      </div>

      <mat-form-field appearance="outline" class="eon-style-form-field no-padding" color="accent" fxLayoutGap="20px">
        <input [formControl]="labelsControl"
               matInput
               class="eon-style-input"
               type="text"
        >
      </mat-form-field>
    </div>
    <h2 class="no-margin">{{'add-opcua-server-dialog.input.server-address.title' | translate}}</h2>
    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
        <mat-label>{{'add-opcua-server-dialog.input.server-address' | translate}}</mat-label>
        <input [formControl]="serverAddressControl"
               matInput
               class="eon-style-input"
               placeholder="XXX.XXX.XXX.XXX"
               type="text"
        >
        <mat-hint align="start">{{'add-opcua-server-dialog.input.server-address.hint' | translate}}</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
        <mat-label>{{'add-opcua-server-dialog.input.port-number' | translate}}</mat-label>
        <input [formControl]="portNumberControl"
               matInput
               class="eon-style-input"
               type="text"
        >
        <mat-hint align="start">{{'add-opcua-server-dialog.input.port-number.hint' | translate}}</mat-hint>
      </mat-form-field>
    </div>

    <h2 class="no-margin">{{'add-opcua-server-dialog.input.authentication.title' | translate}}</h2>

    <mat-radio-group [formControl]="authTypeControl">
      <div fxLayout="column">
        <div fxLayout="row">
          <mat-radio-button value="anonymous"></mat-radio-button>
          {{'add-opcua-server-dialog.input.authentication.anonymous' | translate}}
        </div>
        <div class="basic-auth-container" fxLayout="row" fxLayoutGap="12px">
          <mat-radio-button value="basic"></mat-radio-button>
          <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
            <mat-label>{{'add-opcua-server-dialog.input.user-name' | translate}}</mat-label>
            <input [formControl]="userNameControl"
                   matInput
                   class="eon-style-input"
                   type="text"
            >
          </mat-form-field>

          <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
            <mat-label>{{'add-opcua-server-dialog.input.password' | translate}}</mat-label>
            <input [formControl]="passwordControl"
                   matInput
                   class="eon-style-input"
                   type="text"
            >
          </mat-form-field>
        </div>

        <div class="certificate-auth-container" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
          <mat-radio-button value="certificate"></mat-radio-button>
          <mat-form-field appearance="outline" class="eon-style-form-field no-padding" color="accent">
            <mat-label>{{'add-opcua-server-dialog.input.certificate' | translate}}</mat-label>
            <input [formControl]="certificateControl"
                   matInput
                   class="eon-style-input"
                   type="text"
            >
          </mat-form-field>

          <mat-form-field appearance="outline" class="eon-style-form-field no-padding" color="accent">
            <mat-label>{{'add-opcua-server-dialog.input.private-key' | translate}}</mat-label>
            <input [formControl]="privateKeyControl"
                   matInput
                   class="eon-style-input"
                   type="text"
            >
          </mat-form-field>
        </div>
      </div>
    </mat-radio-group>
  </div>
</div>

<div mat-dialog-actions style="justify-content: space-between;">
  <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}"
                 [mat-dialog-close]="{status: 'closed'}"></eon-ui-button>
  <eon-ui-button icon="radio_checkbox_tick" (click)="verifyConnection()" [disabled]="isVerified"
                 text="{{'add-opcua-server-dialog.button.verify' | translate}}"></eon-ui-button>
  <eon-ui-button icon="add" (click)="validateAndUpdate()" [disabled]="!isVerified"
                 text="{{'add-opcua-server-dialog.button.add' | translate}}"></eon-ui-button>
</div>

