import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Device, MBusConfig, MBusDataPoint, MBusDevice } from '../../../../domain/device.interface';
import { Comport, ComportProtocols } from '../../../../domain/comport';
import { MBusScanDevicesPayload } from '../../../../domain/mbus-scan-devices-payload';
import { GridxDeviceService } from '../../../../services/gridx-device.service';
import { NotificationService } from '../../../../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { ComportService } from '../../../../services/comport.service';
import { MbusPreviewDatapointsComponent } from '../mbus-preview-datapoints/mbus-preview-datapoints.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { AddMbusDeviceDialogComponent } from '../add-mbus-device-dialog/add-mbus-device-dialog.component';
import { DeleteMbusDeviceDialogComponent } from '../delete-mbus-device-dialog/delete-mbus-device-dialog.component';
import { interval, Subscription } from 'rxjs';
import { MbusStopScanDialogComponent } from '../mbus-stop-scan-dialog/mbus-stop-scan-dialog.component';
import { MbusErrorDeviceTableComponent } from '../mbus-error-device-table/mbus-error-device-table.component';
import {
  MbusEditDeviceLabelsDialogComponent
} from '../mbus-edit-device-labels-dialog/mbus-edit-device-labels-dialog.component';
import { MbusEditCsvLabelsDialog } from '../mbus-edit-csv-labels-dialog/mbus-edit-csv-labels-dialog.component';

@Component({
  selector: 'eis-gateway-mbus-device-table',
  templateUrl: './mbus-device-table.component.html',
  styleUrls: ['./mbus-device-table.component.scss']
})
export class MbusDeviceTableComponent implements OnInit, OnChanges {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public tenant: string | undefined;

  @Input()
  public location: string | undefined;

  @Input()
  public mBusConfig: MBusConfig[] = [];

  @Output()
  public deviceSelected = new EventEmitter<any>();

  @Output()
  public configUpdated = new EventEmitter();

  public devices: MBusDevice[] = [];
  public errorDevices: MBusDevice[] = [];
  public devicesDataSource: MatTableDataSource<MBusDevice>;
  public displayedColumns: string[] = ['hasUndeployedDataPoints', 'id', 'manufacturer', 'type', 'version', 'portName', 'dataPointCount', 'contextActions']

  public availablePorts: Comport[] = [];
  public selectedPortControl = new FormControl();

  public selectedDevice: MBusDevice | undefined;
  public scanningDevices: boolean = false;
  public port: Comport;
  public deviceScanStartDate: Date | null;
  public deviceScanEndDate: Date | null;
  public scanError: string | null;
  public ignoreDeviceErrors: boolean | null = false;
  private scanResultSubscription: Subscription | null;
  protected hasIntegratedDevices: boolean = false;

  constructor(
    private gridxService: GridxDeviceService,
    private notificationService: NotificationService,
    private comportService: ComportService,
    private dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.comportService.getComportConfigs(this.gatewaySerial!).subscribe(ports => {
      this.availablePorts = ports.filter(p => p.protocol == ComportProtocols.MBus && this.portNotInUseByModbus(p, ports));

      this.gridxService.getLastMBusDeviceScan(this.gatewaySerial!).subscribe(scan => {
        if (scan != null) {
          const port = this.availablePorts.find(p => p.port == scan.serialPort);
          if(port) {
            this.selectedPortControl.patchValue(port);
          }
        }
      });
    });

    this.selectedPortControl.valueChanges.subscribe(_ => this.getMBusDevices());
    this.checkIntegratedDevices()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['mBusConfig']?.firstChange && changes['mBusConfig']?.currentValue && this.selectedPortControl.value) {
      this.getMBusDevices();
      this.checkIntegratedDevices()
    }
  }

  hasChanges(): boolean {
    return !!this.devices.find(d => d.hasUndeployedDataPoints);
  }

  scanMBusDevices() {
    this.port = this.selectedPortControl.value as Comport;
    this.scanningDevices = true;
    const scanPayload = {
      port: this.port.port,
      portName: this.port.portName,
      baudrate: this.port.baudRate,
      timeout: 20,
    } as MBusScanDevicesPayload;

    this.devicesDataSource.data = [];

    this.gridxService.scanMBusDevices(this.gatewaySerial!, scanPayload).subscribe(result => {
      if(result) {
        this.getMBusDevices();
        this.scanResultSubscription = interval(15 * 1000).subscribe(() => this.getMBusDevices());
      }
    }, (error) => {
      this.notificationService.failure(error.error);
      this.scanningDevices = false
    });
  }

  getMBusDevices() {
    this.selectedDevice = undefined;
    this.port = this.selectedPortControl.value as Comport;
    const scanPayload = {
      port: this.port.port,
      portName: this.port.portName,
      baudrate: this.port.baudRate,
      timeout: 20,
    } as MBusScanDevicesPayload;

    this.gridxService.getMBusDevices(this.gatewaySerial!, scanPayload).subscribe(scanResult => {
      this.deviceScanStartDate = scanResult.startDate;
      this.deviceScanEndDate = scanResult.endDate;
      this.scanError = scanResult.error;
      this.ignoreDeviceErrors = scanResult.ignoreDeviceErrors

      this.devices =  scanResult.meters.filter(d => d.error === "" || d.error === null);
      this.setDevicePortNames(this.devices);
      this.errorDevices =  scanResult.meters.filter(d => d.error);
      this.devicesDataSource = new MatTableDataSource<MBusDevice>(this.sortDevices(this.devices));
      this.devicesDataSource.sort = this.sort;
      this.scanningDevices = !scanResult.done;
      if(scanResult.done) {
        this.scanResultSubscription?.unsubscribe();
      }
    });
  }
  getDataPointCount(deviceId: string): number {
    return this.mBusConfig.filter(conf => conf.secondaryAddress == deviceId).length;
  }

  deviceClicked(device: MBusDevice) {
    this.selectedDevice = device;
    this.deviceSelected.emit({device: device, port: this.selectedPortControl.value as Comport});
  }

  addDeviceManually(): void {
    const comPort = this.selectedPortControl.value as Comport;
    const dialogRef = this.dialog.open(AddMbusDeviceDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.gatewaySerial,
        comPort: comPort,
        port: this.port.port,
        portName: this.port.portName,
        baudrate: this.port.baudRate
      },
      width: '692px',
      height: '464px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((_) => {
      this.getMBusDevices();
    });
  }

  openDeletionDialog(device: Device): void {
    const selectedComPort = this.selectedPortControl.value as Comport;
    const dialogRef = this.dialog.open(DeleteMbusDeviceDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        serial: this.gatewaySerial,
        serialPort: selectedComPort.port,
        device: device,
      },
      width: '792px',
      height: '764px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.success) {
        this.notificationService.success('mbus-delete-device.snackbar.success');

        const deletedDevice = this.devices.find(d => d.id == device.id);
        if(deletedDevice) {
          deletedDevice.isDeleted = true;
          deletedDevice.hasUndeployedDataPoints = true;

          const dataPoints = this.mBusConfig.filter(dp => dp.secondaryAddress == deletedDevice.id);
          for(const dp of dataPoints) {
            dp.actionType = "delete";
            dp.isDeployed = false;
          }
        }
        this.devicesDataSource = new MatTableDataSource<MBusDevice>(this.devices);
      }
    })
  }

  showStopMBusScanDialog() {
    const comPort = this.selectedPortControl.value as Comport;
    this.dialog.open(MbusStopScanDialogComponent, {
      width: '400px',
      height: '250px',
      data: {
        gatewaySerial: this.gatewaySerial,
        serialPort: comPort.port
      }
    }).afterClosed().subscribe(res => {
      if(res == "") {
        return;
      }

      this.scanningDevices = false;
      this.getMBusDevices();
      if(res) {
        this.notificationService.success("mbus-device-table.stop-scan.success");
      }
      else {
        this.notificationService.failure("mbus-device-table.stop-scan.error");
      }
    });
  }

  previewUndeployedChanges() {
    let deviceIds = this.mBusConfig.map(m => m.secondaryAddress);
    deviceIds = [...new Set(deviceIds)];
    this.gridxService.getMBusDevicesDataPoints(this.gatewaySerial!, deviceIds).subscribe(dataPoints => {
      if(dataPoints == null) {
        dataPoints = [];
      }

      const undeployedConfigs = this.mBusConfig.filter(c => c.isDeployed != true);
      const addedDataPoints: MBusDataPoint[] = [];
      const updatedDataPoints: MBusDataPoint[] = [];
      const removedDataPoints: MBusDataPoint[] = [];

      for (let config of undeployedConfigs) {
        const dataPoint = dataPoints.find(dp => dp.secondaryAddress == config.secondaryAddress && dp.key == config.key);
        if (dataPoint) {
          dataPoint.serialPort = config.serialPort;
          dataPoint.pollingFrequency = config.pollingFrequency;
          if(config.actionType == "add") {
            addedDataPoints.push(dataPoint);
          } else if(config.actionType == "update") {
            updatedDataPoints.push(dataPoint);
          } else if(config.actionType == "delete") {
            removedDataPoints.push(dataPoint);
          }
        }
      }

      const dialogRef = this.dialog.open(MbusPreviewDatapointsComponent, {
        panelClass: 'dialog-container-custom',
        disableClose: true,
        data: {
          addedConfigs: addedDataPoints,
          updatedConfigs: updatedDataPoints,
          removedConfigs: removedDataPoints,
          removedDevices: this.devices.filter(d => d.isDeleted),
          serial: this.gatewaySerial,
        },
        width: '850px',
        height: '910px',
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getMBusDevices();
          this.configUpdated.emit();
        }
      });
    });
  }

  showErrorDevicesInDialog() {
    const dialogRef = this.dialog.open(MbusErrorDeviceTableComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        devices: this.errorDevices,
        serial: this.gatewaySerial,
        port: this.port
      },
      width: '750px',
      height: '610px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getMBusDevices();
      }
    });
  }

  private getPortName(mbusDevice: MBusDevice): string {
    const config = this.mBusConfig.find(m => m.secondaryAddress == mbusDevice.id);
    if(config) {
      const port = this.availablePorts.find(p => p.port == config.serialPort);
      if (port) {
        return port.portName.replace("RS232 ", "");
      }
    }

    return "";
  }

  private portNotInUseByModbus(port: Comport, ports: Comport[]) {
    let otherPort = ports.find(p => p.port == port.port && p.portName !== port.portName)
    return (!(otherPort!!.inUse && otherPort!!.protocol == ComportProtocols.ModbusRtu));
  }

  private setDevicePortNames(devices: MBusDevice[]) {
    for(const device of devices) {
      device.portName = this.getPortName(device);
    }
  }

  private sortDevices(devices: MBusDevice[]) : MBusDevice[] {
    return devices.sort((a, b) =>
      !a.manufacturer && b.manufacturer ? -1 : 1
    );
  }

  openEditLabelDialog(device: MBusDevice) {
    const dialogRef = this.dialog.open(MbusEditDeviceLabelsDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.gatewaySerial,
        device: device,
      },
      width: '792px',
      height: '640px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      switch (response.status) {
        case "200":
          this.notificationService.success( 'mbus-device-table.snackbar.label-apply-success');
          break;
        case "closed":
          break;
        default:
          this.notificationService.failure('mbus-device-table.snackbar.label-apply-error');
      }
    });
  }

  openCSVLabelsDialog(device: MBusDevice): void {
    const dialogRef = this.dialog.open(MbusEditCsvLabelsDialog, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.gatewaySerial,
        device: device,
        mBusConfig: this.mBusConfig,
        location: this.location,
      },
      width: '792px',
      height: '787px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((response) => {
      console.log(response);
      switch (response.status) {
        case "200":
          console.log(response);
          this.notificationService.success( 'gateway-details.snackbar.mbus-device-csv-labels-update.success',
            {updatedDatapoints: response.updatedDatapoints});
          break;
        case "409":
          console.log(response);
          this.notificationService.failure('gateway-details.snackbar.mbus-device-csv-labels-update.error',
            {updatedDatapoints: 0});
          break;
        case "closed":
          break;
        default:
          this.notificationService.failure('gateway-details.snackbar.device-update.error');
      }
    });
  }

  checkIntegratedDevices(): void {
    this.gridxService.getMBusConfig(this.gatewaySerial!!).subscribe( (result) =>
      {
        this.hasIntegratedDevices = result.length != 0;
      }
    )
  }
}
