<eis-gateway-header class="header"></eis-gateway-header>
<eis-gateway-announcements></eis-gateway-announcements>
<div fxFlex="grow" fxLayout="column"
     fxLayoutAlign="space-between">

  <div class="main-content" fxFlex>
    <ng-content></ng-content>
  </div>

</div>

<eis-gateway-footer></eis-gateway-footer>
