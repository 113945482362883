import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DeploymentService } from '../../../../services/deployment.service';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '../../../../services/notification.service';
import { OPCUAEIoTDataPoint } from '../../../../domain/opcua-eiot-data-point';

@Component({
  selector: 'eis-gateway-opcua-data-point-table',
  templateUrl: './opcua-data-point-table.component.html',
  styleUrls: ['./opcua-data-point-table.component.scss']
})
export class OpcuaDataPointTableComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public datapoints: MatTableDataSource<OPCUAEIoTDataPoint>;

  @Input()
  public tenant: string | undefined;

  @Output()
  public updateDatasource = new EventEmitter<string>();

  public displayedColumns: string[] = ['online', 'deviceName', 'parentName', 'name', 'description', 'value', 'unit', 'pollingFrequency', 'lastUpdated', 'contextActions']

  constructor(private deploymentService: DeploymentService,
              private router: Router,
              private clipboard: Clipboard,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
  }

  refresh(): void {
    this.table.renderRows();
  }

  async copyLabelsToClipboard(labels: string) {
    if(!labels) {
      return;
    }

    this.clipboard.copy(labels);
    await this.notificationService.success("Copied to clipboard");
  }

  ngAfterViewInit(): void {
    this.datapoints.sort = this.sort;
  }

  formatValue(dataPoint: OPCUAEIoTDataPoint) {
    if(dataPoint.lastValue?.includes(".")) {
      const floatValue = parseFloat(dataPoint.lastValue);
      if(floatValue.toString() == dataPoint.lastValue) {
        const parts = floatValue.toString().split('.');
        if (parts[1].length > 3) {
          return floatValue.toFixed(3);
        }
      }
    }

    return dataPoint.lastValue;
  }

  exportDataPoints() {

  }
}
