import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import { OPCUADevice } from '../domain/opcua-device';
import { triggerLoader } from '../components/decorators/loaderDecorator';
import { checkFullyConnected } from '../components/decorators/checkConnectedDecorator';
import { OPCUATreeNode } from '../domain/opcua-tree-node';
import { OPCUADataPoint } from '../domain/opcua-data-point';
import { OPCUAConfig } from '../domain/opcua-config';
import { OPCUAEIoTDataPoint } from '../domain/opcua-eiot-data-point';

@Injectable({
  providedIn: 'root'
})
export class OPCUADeviceService {
  private readonly SERVICE_URL = `${environment.backendUrl}/opcua`;

  constructor(private http: HttpClient) {
  }

  public getAllDevices(gatewaySerial?: string): Observable<OPCUADevice[]> {
    return this.http.get<OPCUADevice[]>(this.SERVICE_URL + '/' + gatewaySerial);
  }

  @triggerLoader
  @checkFullyConnected
  addDevice(device: OPCUADevice) {
    return this.http.post<OPCUADevice>(this.SERVICE_URL, device);
  }

  updateDevice(device: OPCUADevice) {
    return this.http.put<OPCUADevice>(this.SERVICE_URL, device);
  }

  deleteDevice(device: OPCUADevice, deleteInEoit: boolean) {
    return this.http.delete(this.SERVICE_URL + '/' + device.id + "?deleteInEiot=" + deleteInEoit);
  }

  @triggerLoader
  @checkFullyConnected
  verifyDeviceConnection(device: OPCUADevice) {
    return this.http.post<boolean>(this.SERVICE_URL + '/verify', device);
  }

  getDataPoints(device: OPCUADevice, id: string) {
    return this.http.post<OPCUADataPoint[]>(this.SERVICE_URL + '/datapoints?nodeId=' + id, device);
  }

  browseChildren(device: OPCUADevice, id: string) {
    return this.http.post<OPCUATreeNode[]>(this.SERVICE_URL + '/children?nodeId=' + id, device);
  }

  getDeviceConfig(gatewaySerial: string, deviceId: string) {
    return this.http.get<OPCUAConfig[]>(this.SERVICE_URL + '/config/' + gatewaySerial + '/' + deviceId);
  }

  @triggerLoader
  @checkFullyConnected
  updateDeviceConfig(gatewaySerial: string, configs: OPCUAConfig[]) {
    return this.http.post<OPCUAConfig[]>(this.SERVICE_URL + '/config/' + gatewaySerial, configs);
  }

  @triggerLoader
  @checkFullyConnected
  removeDeviceConfig(gatewaySerial: string, configs: OPCUAConfig[]) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: configs  // Pass the list of objects in the body
    };
    return this.http.delete(this.SERVICE_URL + '/config/' + gatewaySerial, httpOptions);
  }

  getGatewayConfig(gatewaySerial: string, deviceIds: string[]) {
    const ids = deviceIds.join(",");
    return this.http.get<OPCUAConfig[]>(this.SERVICE_URL + '/gateway/' + gatewaySerial + '/configs?ids=' + ids);
  }

  @triggerLoader
  @checkFullyConnected
  public applyOPCUAConfig(serial: string, deleteDataPointsInIoT: boolean): Observable<boolean> {
    return this.http.post<boolean>(this.SERVICE_URL + "/gateway/" + serial + "/config/apply/" + deleteDataPointsInIoT, {});
  }

  getEIoTDataPoints(gatewaySerial: string, deviceId?: string) {
    if(deviceId) {
      return this.http.get<OPCUAEIoTDataPoint[]>(this.SERVICE_URL + '/gateway/' + gatewaySerial + '/datapoints?deviceId=' + deviceId);
    } else {
      return this.http.get<OPCUAEIoTDataPoint[]>(this.SERVICE_URL + '/gateway/' + gatewaySerial + '/datapoints');
    }
  }

  validateOPCUADataPointLabels(gatewaySerial: string, deviceId: string, file: File) {
    const data = new FormData();
    data.append("gatewaySerial", gatewaySerial);
    data.append("deviceId", deviceId);
    data.append("dataPoints", file, file.name);
    return this.http.post<any>(this.SERVICE_URL + '/datapoint/labels/validate', data) as Observable<any>;
  }

  @triggerLoader
  @checkFullyConnected
  updateOPCUADataPointLabels(gatewaySerial: string, deviceId: string, file: File) {
    const data = new FormData();
    data.append("gatewaySerial", gatewaySerial);
    data.append("deviceId", deviceId);
    data.append("dataPoints", file, file.name);
    return this.http.put<any>(this.SERVICE_URL + '/datapoint/labels', data) as Observable<any>;
  }
}
