import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridxDeviceService } from '../../../services/gridx-device.service';
import { NotificationService } from '../../../services/notification.service';
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import 'dayjs/locale/de'
import * as relativeTime from 'dayjs/plugin/relativeTime';

@Component({
  selector: 'eis-gateway-diagnostics-dialog',
  templateUrl: './diagnostics-dialog.component.html',
  styleUrls: ['./diagnostics-dialog.component.scss']
})
export class DiagnosticsDialogComponent implements OnInit {
  pingInputControl: FormControl = new FormControl('', [Validators.required]);
  telnetIpControl: FormControl = new FormControl('', [Validators.required]);
  telnetPortControl: FormControl = new FormControl('', [Validators.required]);
  logLines: string[] = [];
  logFilterType = "modbus,mbus,opcua";

  constructor(@Inject(MAT_DIALOG_DATA) public data: {serial: string},
              private gridxService: GridxDeviceService,
              public notificationService: NotificationService,) { }

  ngOnInit(): void {
    dayjs.extend(utc);
    dayjs.locale('de');
  }

  ping() {
    if (this.pingInputControl.value) {
      this.gridxService.pingAddress(this.data.serial, this.pingInputControl.value).subscribe(res => {
        this.prependLog(res);
      }, error => this.notificationService.failure(error.error));
    } else{
      this.notificationService.failure("Please enter an IP-address or domain name")
    }
  }

  telnet() {
    if (this.telnetIpControl.value && this.telnetPortControl.value) {
      this.gridxService.checkTcpPort(this.data.serial, this.telnetIpControl.value, this.telnetPortControl.value).subscribe(res => {
        this.prependLog(res);
      }, error =>this.notificationService.failure(error.error));
    } else{
      this.notificationService.failure("Please enter an IP-address or a domain name and a port number")
    }
  }

  getGatewayLogs() {
    this.gridxService.getGatewayLogs(this.data.serial, this.logFilterType).subscribe(res => {
      let lines = res.split("\n");
      lines = lines.filter(n => n).reverse();
      for(let i = 0; i < lines.length; i++) {
        lines[i] = this.replaceDate(lines[i]);
      }
      this.logLines = lines.concat(["","","", ""]).concat(this.logLines);
    }, error => this.notificationService.failure(error.error));
  }

  openGatewayLog() {
    window.open("https://monitoring.eon.com/de-DE/app/s187_sonic_digital_twin/search?q=search%20source%3D%22http%3Ahttp_sonic_digital_twin_s187_eis_gateway%22%7C%20search%20serialnumber%3D%22" + this.data.serial + "%22")
  }

  private prependLog(logs: string) {
    let lines = logs.split("\n");
    lines = lines.filter(n => n);
    this.logLines = lines.concat(["","","", ""]).concat(this.logLines);
  }

  private replaceDate(log: string) {
    const utcDate = log.substring(0, 32);
    const localDate = dayjs(utcDate).local().locale('de');

    return log.replace(utcDate, "<label class='date-label'>" + localDate.format("DD.MM.YYYY HH:mm:ss") + "</label>");
  }
}
